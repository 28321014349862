import {IInvestmentOption, IInvestmentProduct} from "../Caps/types";
import {yn} from "../helpers/yn";

export const checkOverdraftsIddBothRequired = () =>
  yn(process.env.REACT_APP_OVERDRAFFTS_IDD_BOTH_REQUIED);

export const SPECIAL_SP_PRODUCS = ["novis gemini life"];
export const SPECIAL_SP_OPTIONS = ["portafoglio, premio unico"];
export const isSpecialSPProduct = (product: IInvestmentProduct): boolean => {
  return SPECIAL_SP_PRODUCS.includes(product.name.toLowerCase());
};
export const isSpecialSPOption = (option: IInvestmentOption): boolean => {
  return SPECIAL_SP_OPTIONS.includes(option.name.toLowerCase());
};

export const MAINTENANCE_MODE = yn(process.env.REACT_APP_MAINTEINANCE_MODE);

export const NEW_VERSION:
  | {dateStop: string; dateStartNewVersion: string; url: string}
  | false = JSON.parse(process.env.REACT_APP_NEW_VERSION ?? "false");
