import {IRootState} from "../../redux/reducers";

export const selectIsModalOpen = (state: IRootState) => state.eSign.isModalOpen;
export const selectIsUpdatingPhone = (state: IRootState) =>
  state.eSign.isUpdatingPhone;
export const selectPDFType = (state: IRootState) => state.eSign.pdfType;
export const selectReferenceId = (state: IRootState) => state.eSign.referenceId;
export const selectAdditionalParams = (state: IRootState) =>
  state.eSign.additionalParams;
export const selectInPlaceOfContractor = (state: IRootState) =>
  state.eSign.inPlaceOfContractor;
