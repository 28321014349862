import React from "react";
import {
  FormFeedback,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

const RenderField = ({
  input,
  meta: {touched, error, warning, dirty},
  prependAddOn,
  appendAddOn,
  helpText,
  manualWaring,
  ...props
}: any) => {
  const formattedPrependAddOn =
    typeof prependAddOn === "string" ? (
      <InputGroupText>{prependAddOn}</InputGroupText>
    ) : (
      prependAddOn
    );
  const formattedAppendAddOn =
    typeof appendAddOn === "string" ? (
      <InputGroupText>{appendAddOn}</InputGroupText>
    ) : (
      appendAddOn
    );

  return (
    <>
      <InputGroup>
        {formattedPrependAddOn}
        <Input
          {...(props.noValidate !== true && touched ? {invalid: !!error} : {})}
          {...(props.noValidate !== true && dirty ? {valid: !error} : {})}
          {...input}
          {...props}
        />
        {formattedAppendAddOn}
      </InputGroup>
      {error && touched && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
      {!error && touched && (warning || manualWaring) && (
        <FormText color="warning" className="d-block">
          {warning || manualWaring}
        </FormText>
      )}
      <FormText color="secondary" className="d-block">
        {helpText}
      </FormText>
    </>
  );
};

export default RenderField;
