import last from "lodash/last";
import moment from "moment/moment";
import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import errors, {ErrorCodes} from "../../helpers/errors";
import {
  BeneProposal,
  BeneProposalDB,
  BeneProposalsParams,
  parseBeneProposalDB,
} from "../types";

const apiUrl = "bene-proposals";

interface GetBeneProposalsResponse {
  benes: BeneProposalDB[];
  numBenes: number;
}
interface GetBeneProposalsData {
  beneProposals: BeneProposal[];
  numBeneProposals: number;
}

interface CreateBeneProposalResponse {
  bene: BeneProposalDB;
}

interface GetBeneProposalResponse {
  bene: BeneProposalDB;
}

interface SendBeneProposalEmailResponse {
  bene: BeneProposalDB;
}

interface SendBeneProposalToBeneResponse {
  bene: BeneProposalDB;
}

export const beneProposalsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBeneProposals: builder.query<GetBeneProposalsData, BeneProposalsParams>({
      query: (params) => ({
        url: apiUrl,
        params,
      }),
      providesTags: (data) =>
        providesTagList(data?.beneProposals, Tags.BeneProposal),
      transformResponse: ({
        benes,
        numBenes,
      }: SuccessServerResponse<GetBeneProposalsResponse>) => {
        return {
          beneProposals: benes.map(parseBeneProposalDB),
          numBeneProposals: numBenes,
        };
      },
    }),
    createBeneProposal: builder.mutation<
      BeneProposal,
      {
        user: string;
        contractor: string;
        cap: string;
        dateEffect: string;
        jsonProduct: string;
      }
    >({
      query: (data) => {
        const dateEffect =
          data.dateEffect &&
          moment(data.dateEffect, "DD/MM/YYYY").format("YYYY-MM-DD");

        return {
          url: apiUrl,
          method: "POST",
          body: {...data, dateEffect},
        };
      },
      transformResponse: ({
        bene,
      }: SuccessServerResponse<CreateBeneProposalResponse>) => {
        return parseBeneProposalDB(bene);
      },
      invalidatesTags: [{type: Tags.BeneProposal, id: "LIST"}],
    }),
    getBeneProposal: builder.query<BeneProposal, string>({
      query: (beneProposalId) => ({
        url: `${apiUrl}/${beneProposalId}`,
      }),
      transformResponse: ({
        bene,
      }: SuccessServerResponse<GetBeneProposalResponse>) => {
        return parseBeneProposalDB(bene);
      },
      providesTags: (result, error, beneProposalId) => [
        {type: Tags.BeneProposal, id: beneProposalId},
      ],
    }),
    sendBeneProposalEmail: builder.mutation<BeneProposal, string>({
      query: (beneProposalId) => ({
        url: `${apiUrl}/${beneProposalId}/send-email`,
        method: "POST",
      }),
      transformResponse: ({
        bene,
      }: SuccessServerResponse<SendBeneProposalEmailResponse>) => {
        return parseBeneProposalDB(bene);
      },
      invalidatesTags: [{type: Tags.BeneProposal, id: "LIST"}],
      async onQueryStarted(beneProposalId, {dispatch, queryFulfilled}) {
        try {
          const {data: beneProposal} = await queryFulfilled;

          dispatch(
            beneProposalsApi.util.updateQueryData(
              "getBeneProposal",
              beneProposalId,
              () => {
                return beneProposal;
              }
            )
          );
        } catch {}
      },
    }),
    sendBeneProposalToBene: builder.mutation<BeneProposal, string>({
      queryFn: async (beneProposalId, baseQueryApi, options, baseQuery) => {
        const response = await baseQuery({
          url: `${apiUrl}/${beneProposalId}/send`,
          method: "POST",
        });

        if (response.error) {
          return {error: response.error};
        }

        const serverResponse =
          response.data as SuccessServerResponse<SendBeneProposalToBeneResponse>;
        const beneProposal = parseBeneProposalDB(serverResponse.bene);

        const lastData = last(beneProposal.data);
        const uid = lastData?.outputAccessoProdotto?.uid;

        if (!uid) {
          // Non abbiamo lo uid
          return {error: errors[ErrorCodes.BENE_UID_NOT_VALID]};
        } else if (
          lastData?.outputAccessoProdotto?.outcome?.messages !== null
        ) {
          // C'è lo uid ma abbiamo cmq degli errori conosciuti
          return {
            error: {
              ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
              message:
                `Bene Assicurazioni ha tornato questo errore: "${lastData?.outputAccessoProdotto?.outcome?.messages[0]}". ` +
                errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
            },
          };
        }

        return {data: beneProposal};
      },
      invalidatesTags: [{type: Tags.BeneProposal, id: "LIST"}],
      async onQueryStarted(beneProposalId, {dispatch, queryFulfilled}) {
        try {
          const {data: beneProposal} = await queryFulfilled;

          dispatch(
            beneProposalsApi.util.updateQueryData(
              "getBeneProposal",
              beneProposalId,
              () => {
                return beneProposal;
              }
            )
          );
        } catch {}
      },
    }),
  }),
});
