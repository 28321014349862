import React from "react";
import {Link, Navigate} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";
import {IconBack} from "../Icons";
import {emptyAuth} from "../User/consts";
import {authApi} from "../User/slice";

const NotFoundPage = () => {
  const {data: {user: loggedUser} = emptyAuth} = authApi.usePingQuery();

  return (
    <Container className="main-center">
      {!loggedUser && <Navigate to="/" replace />}
      <img
        src="/img/logo.svg"
        className="logo mx-auto mb-4 d-block"
        alt={`${process.env.REACT_APP_TITLE} logo`}
      />
      <Row>
        <Col className="text-center">
          <h3 className="mb-4">Pagina non trovata ☹</h3>
          <p>
            <Link to="/">
              <Button color="light" outline>
                <IconBack /> Torna alla pagina principale
              </Button>
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
