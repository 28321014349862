import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IApiError from "../../types/IApiError";
import {authActions} from "../../User/slice";
import {NewCapModalTypes} from "../types";

interface CapsState {
  chosenCap?: string;
  idFileError?: IApiError;
  isModalOpen: {[key in NewCapModalTypes]?: boolean};
  isModalOpenId?: string;
  errorExistingUserId?: string;
  errorCreateContractorAnotherAdvisor?: boolean;
}

const capsInitialState: CapsState = {isModalOpen: {}};

const capsSlice = createSlice({
  name: "caps",
  initialState: capsInitialState,
  reducers: {
    choseCap: (state, action: PayloadAction<string | undefined>) => {
      state.chosenCap = action.payload;
    },
    idFileFailed: (state, action: PayloadAction<IApiError>) => {
      state.idFileError = action.payload;
    },
    capModalOpened: (
      state,
      action: PayloadAction<{modalType: NewCapModalTypes; id?: string}>
    ) => {
      state.isModalOpen[action.payload.modalType] = true;
      state.isModalOpenId = action.payload.id;
    },
    capModalClosed: (
      state,
      action: PayloadAction<{modalType: NewCapModalTypes}>
    ) => {
      state.isModalOpen[action.payload.modalType] = false;
    },
    errorExistingUserIdReceived: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.errorExistingUserId = action.payload;
    },
    errorCreateContractorAnotherAdvisorReceived: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.errorCreateContractorAnotherAdvisor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.loggedOut, () => {
      return capsInitialState;
    });
  },
});

export const capsReducer = capsSlice.reducer;
export const capsActions = capsSlice.actions;
