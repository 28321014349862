import {z} from "zod";
import {Cap, CapDB, IInvestmentCompany, parseCapDB} from "../Caps/types";
import {User, UserDB} from "../Users/types";

export interface ElementaryClassDB {
  cap: CapDB;
  company: IInvestmentCompany;
  contractor: UserDB;
  date: string;
  dateSendEmail?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  jsonDocs: string;
  jsonUser: string;
  productsElementaryClass?: ProductElementaryClass;
  profitCenter: string;
  status: ElementaryClassStatus;
  user: UserDB;
}

export interface ElementaryClass {
  cap: Cap;
  company: IInvestmentCompany;
  contractor: User;
  date: string;
  dateSendEmail?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  docs: Docs;
  jsonUser: string;
  productsElementaryClass?: ProductElementaryClass;
  profitCenter: string;
  status: ElementaryClassStatus;
  user: User;
}

export const parseElementaryClassDB = (
  elementaryClassGeneric: ElementaryClassDB
) => {
  const {cap, jsonDocs, ...rest} = elementaryClassGeneric;
  const elementaryClass: ElementaryClass = {
    ...rest,
    cap: parseCapDB(cap),
    docs: JSON.parse(jsonDocs) as Docs,
  };

  return elementaryClass;
};

export interface Docs {
  flagIsTcm: boolean;
  flagReadAllegato3: string;
  flagReadAllegato4Ter: string;
  flagReadAllegato4: string;
  flagReadAvvenutaConsegna: string;
  flagReadSetInformativo: string;
  flagReadDichiarazioneCoerenza: string;
  flagConsegnaManoSetInformativo?: string;
}

export interface ProductElementaryClass {
  id: string;
  name: string;
  type: "TCM" | "LTC";
}

export enum ElementaryClassStatus {
  Enabled = "attivo",
  Intermediate = "intermedio",
  Disabled = "inattivo",
}

export enum ElementaryClassClassFilter {
  MissingAdvisorESign = "MissingAdvisorESign",
  MissingContractorESign = "MissingContractorESign",
  WaitingContractorESign = "WaitingContractorESign",
  ContractorESigned = "ContractorESigned",
}

export const elementaryClassesParamsSchema = z.object({
  contractor: z.string().optional(),
  company: z.string().optional(),
  date: z.string().optional(),
  limit: z
    .string()
    .default("50")
    .transform((str) => parseInt(str, 10)),
  offset: z
    .string()
    .default("0")
    .transform((str) => parseInt(str, 10)),
  sort: z.string().default("-date"),
  user: z.string().optional(),
  status: z.nativeEnum(ElementaryClassClassFilter).optional(),
});
export type ElementaryClassesParams = z.infer<
  typeof elementaryClassesParamsSchema
>;
