import moment from "moment";
import {denormalize, normalize, schema} from "normalizr";
import {IInvestmentProduct} from "../Caps/types";

// TODO: L'idea è di creare per le varie relazione (es. cap.user) una variabile che contenga
//  l'id del cap (i.e. capId:string) e una variabile che contenga il cap (i.e. cap?:ICap)
//  Le var verrebbero poi valorizzate con il risultato della chiamata al server nella
//  transformResponse della baseQuery

export interface UserDB {
  address?: string;
  birthday?: string;
  birthplace?: string;
  birthplaceBExist?: boolean;
  birthplaceCSiglaProvincia?: string;
  birthplaceIdObject?: string;
  businessPhone?: string;
  city?: string;
  companiesId?: Record<number, boolean>;
  companyEmail?: string;
  companyName?: string;
  companyRole?: string;
  companyUserId?: string;
  createdAt: string;
  dateRui?: string;
  dateRuiCompany?: string;
  definitiveCollaborationCode?: string;
  education?: EducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: GenderTypes;
  id: string;
  jsonIdentificationHistory?: string;
  jsonPrivacy?: string;
  jsonPrivacySubscription?: string;
  lastLogin?: string;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: boolean;
  pec?: string;
  personalEmail?: string;
  personalPhone?: string;
  position?: string;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  registeredOffice?: string;
  rolesId: Record<number, boolean>;
  ruiCode?: string;
  ruiCodeCompany?: string;
  status: UserStatus;
  streetNumber?: string;
  surname: string;
  temporaryCollaborationCode?: string;
  updatedAt: string;
  usid: string;
  vatNumber?: string;
  version: string;
  website?: string;
  zipCode?: string;
}

export interface User {
  address?: string;
  birthday?: string;
  birthplace?: string;
  birthplaceBExist?: boolean;
  birthplaceCSiglaProvincia?: string;
  birthplaceIdObject?: string;
  businessPhone?: string;
  city?: string;
  companiesId?: {[key: number]: boolean};
  companyEmail?: string;
  companyName?: string;
  companyRole?: string;
  companyUserId?: string;
  createdAt: string;
  dateRui?: string;
  dateRuiCompany?: string;
  definitiveCollaborationCode?: string;
  education?: EducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: GenderTypes;
  id: string;
  identification?: IIdentificationHistory;
  lastLogin?: string;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: boolean;
  pec?: string;
  personalEmail?: string;
  personalPhone?: string;
  position?: string;
  privacy?: IPrivacyHistory;
  privacySubscription?: IPrivacySubscriptionHistory;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  registeredOffice?: string;
  rolesId: Record<number, boolean>;
  ruiCode?: string;
  ruiCodeCompany?: string;
  status: UserStatus;
  streetNumber?: string;
  surname: string;
  temporaryCollaborationCode?: string;
  updatedAt: string;
  usid: string;
  vatNumber?: string;
  version: string;
  website?: string;
  zipCode?: string;
}

export const parseUserDB = (userGeneric: UserDB): User => {
  const {
    jsonIdentificationHistory,
    jsonPrivacy,
    jsonPrivacySubscription,
    password,
    ...rest
  } = userGeneric;
  const user: User = {
    ...rest,
    identification: jsonIdentificationHistory
      ? (JSON.parse(jsonIdentificationHistory) as IIdentificationHistory)
      : undefined,
    password: !!password,
    privacy: jsonPrivacy
      ? (JSON.parse(jsonPrivacy) as IPrivacyHistory)
      : undefined,
    privacySubscription: jsonPrivacySubscription
      ? (JSON.parse(jsonPrivacySubscription) as IPrivacySubscriptionHistory)
      : undefined,
  };

  return user;
};

export enum UserStatus {
  Active = "1",
  Inactive = "-1",
  WaitForActivation = "0",
}

export enum UserRoles {
  SuperAdmin = "1",
  Advisor = "2",
  Supervisor = "3",
  Backoffice = "5",
  UserManage = "6",
  Contractor = "7",
}

export enum MaritalStatuses {
  Unmarried = "celibe",
  Married = "coniugato",
  LiveTogether = "convivente",
  Divorced = "divorziato",
  Widowed = "vedovo",
}

export enum EducationTypes {
  None = "1",
  Primary = "2",
  Secondary = "3",
  Degree = "4",
  FinancialDegree = "5",
}

export enum GenderTypes {
  Male = "m",
  Female = "f",
}

export enum IdTypes {
  Card = "Carta d'identità",
  Passport = "Passaporto",
  Drive = "Patente",
}

export interface IIdentificationSnapshot {
  advisorAccept: boolean;
  esignId?: string;
  contractorEsignId?: string;
  date: string;
  expirationDate: string;
  fileIdBackName: string;
  fileIdFrontName: string;
  fileIdFaceName?: string;
  fileIdFCName?: string;
  idNumber: string;
  idType: keyof typeof IdTypes;
  releaseDate: string;
  releasedBy: string;
}

export type IIdentificationHistory = IIdentificationSnapshot[];

export interface IPrivacySnapshot {
  version: string;
  values: {[Key: string]: boolean};
  date: string;
  esignId?: string;
}

// Privacy nuove sono array, ma le vecchie possono essere od oggetti snapshot oppure
// true per gli advisor
export type IPrivacyHistory = IPrivacySnapshot[] | IPrivacySnapshot | "true";

export type IPrivacySubscriptionHistory = IPrivacySnapshot[];

// È l'interfaccia degli user salvati nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface IUserEntity {
  address?: string;
  birthday?: string;
  birthplace?: string;
  birthplaceBExist: string;
  birthplaceCSiglaProvincia: string;
  birthplaceIdObject: string;
  businessPhone?: string;
  city?: string;
  companiesId?: {[key: number]: boolean};
  companyEmail?: string;
  companyName?: string;
  companyRole?: string;
  companyUserId?: string;
  createdAt?: string;
  dateRui?: string;
  dateRuiCompany?: string;
  definitiveCollaborationCode?: string;
  education?: EducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: GenderTypes;
  id: string;
  jsonIdentificationHistory?: string;
  jsonPrivacy?: string;
  jsonPrivacySubscription?: string;
  lastLogin?: string;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: string;
  pec?: string;
  personalEmail?: string;
  personalPhone?: string;
  position?: string;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  registeredOffice?: string;
  rolesId?: Record<number, boolean>;
  ruiCode?: string;
  ruiCodeCompany?: string;
  status: UserStatus;
  streetNumber?: string;
  surname: string;
  temporaryCollaborationCode?: string;
  updatedAt?: string;
  usid: string;
  vatNumber?: string;
  version?: string;
  website?: string;
  zipCode?: string;
}

// È l'interfaccia degli user idratati
//  i.e. con i tipi e con le altre entità esplicitate
export interface IUser {
  address?: string;
  birthday?: moment.Moment;
  birthplace?: string;
  birthplaceBExist?: boolean;
  birthplaceCSiglaProvincia?: string;
  birthplaceIdObject?: string;
  businessPhone?: string;
  city?: string;
  companiesId?: number[];
  companyEmail?: string;
  companyName?: string;
  companyRole?: string;
  companyUserId?: string;
  createdAt: string;
  dateRui?: moment.Moment;
  dateRuiCompany?: string;
  definitiveCollaborationCode?: string;
  education?: EducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: GenderTypes;
  id: string;
  identification?: IIdentificationHistory;
  lastLogin?: moment.Moment;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: boolean;
  pec?: string;
  personalEmail?: string;
  personalPhone?: string;
  position?: string;
  privacy?: IPrivacyHistory;
  privacySubscription?: IPrivacySubscriptionHistory;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  registeredOffice?: string;
  rolesId: number[];
  ruiCode?: string;
  ruiCodeCompany?: string;
  status: UserStatus;
  streetNumber?: string;
  surname: string;
  temporaryCollaborationCode?: string;
  updatedAt: string;
  usid: string;
  vatNumber?: string;
  version: string;
  website?: string;
  zipCode?: string;
}

export interface IDehydratedUsers {
  result: string[];
  entities: {
    users: IUsersEntities;
  };
}
export interface IDehydratedUser {
  result: string;
  entities: {
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
export const userSchema = userEntity;

export interface IUsersEntities {
  [key: string]: IUserEntity;
}

export const rolesIdObjToBoolArr = (rolesIdObj: {
  [key: number]: boolean;
}): (undefined | boolean)[] => {
  const rolesIdBoolArr: (undefined | boolean)[] = [];
  Object.keys(rolesIdObj).forEach((key) => {
    rolesIdBoolArr[parseInt(key, 10)] = true;
  });
  return rolesIdBoolArr;
};
export const rolesIdObjToArr = (
  rolesIdObj: Partial<Record<number, boolean>>
): number[] => {
  const rolesIdArr: number[] = [];
  Object.keys(rolesIdObj).forEach((key) => {
    rolesIdArr.push(parseInt(key, 10));
  });
  return rolesIdArr;
};
export const rolesIdArrToObj = (
  rolesIdArr: number[]
): {[key: number]: boolean} => {
  const rolesIdObj: {[key: number]: boolean} = {};
  rolesIdArr.forEach((role) => {
    rolesIdObj[role] = true;
  });
  return rolesIdObj;
};

export const dehydrateUserProperties = (userGeneric: IUser) => {
  const {
    birthday,
    companiesId,
    dateRui,
    identification,
    lastLogin,
    privacy,
    privacySubscription,
    rolesId,
    ...rest
  } = userGeneric;
  const user: UserDB = {
    ...rest,
    birthday: birthday && birthday.format("LL"),
    dateRui: dateRui && dateRui.format("LL"),
    jsonIdentificationHistory: JSON.stringify(identification),
    jsonPrivacy: JSON.stringify(privacy),
    jsonPrivacySubscription: JSON.stringify(privacySubscription),
    lastLogin: lastLogin && lastLogin.format("LL LTS"),
    rolesId: rolesId && rolesIdArrToObj(rolesId),
    companiesId: companiesId && rolesIdArrToObj(companiesId),
  };

  return user;
};

export function normalizeUser(user: UserDB): IDehydratedUser;
export function normalizeUser(user: UserDB[]): IDehydratedUsers;
export function normalizeUser(user: UserDB | UserDB[]) {
  if (Array.isArray(user)) {
    return normalize(user, [userSchema]);
  } else {
    return normalize(user, userSchema);
  }
}

export function dehydrateUser(user: IUser): IDehydratedUser;
export function dehydrateUser(user: IUser[]): IDehydratedUsers;
export function dehydrateUser(user: IUser | IUser[]) {
  if (Array.isArray(user)) {
    // Risultato multiplo
    return normalizeUser(user.map(dehydrateUserProperties));
  } else {
    // Risultato singolo
    return normalizeUser(dehydrateUserProperties(user));
  }
}

export const hydrateUserProperties = (userGeneric: UserDB) => {
  if (typeof userGeneric === "string") {
    return {id: userGeneric as string} as IUser;
  }
  const {
    birthday,
    companiesId,
    dateRui,
    jsonIdentificationHistory,
    jsonPrivacy,
    jsonPrivacySubscription,
    lastLogin,
    password,
    rolesId,
    ...rest
  } = userGeneric;
  const user: IUser = {
    ...rest,
    birthday: birthday ? moment(birthday) : undefined,
    dateRui: dateRui ? moment(dateRui) : undefined,
    identification: jsonIdentificationHistory
      ? (JSON.parse(jsonIdentificationHistory) as IIdentificationHistory)
      : undefined,
    lastLogin: lastLogin ? moment(lastLogin) : undefined,
    password: !!password,
    privacy: jsonPrivacy
      ? (JSON.parse(jsonPrivacy) as IPrivacyHistory)
      : undefined,
    privacySubscription: jsonPrivacySubscription
      ? (JSON.parse(jsonPrivacySubscription) as IPrivacySubscriptionHistory)
      : undefined,
    rolesId: rolesId && rolesIdObjToArr(rolesId),
    companiesId: companiesId && rolesIdObjToArr(companiesId),
  };

  return user;
};

export function hydrateUser({result, entities}: IDehydratedUser): IUser;
export function hydrateUser({result, entities}: IDehydratedUsers): IUser[];
export function hydrateUser({
  result,
  entities,
}: IDehydratedUser | IDehydratedUsers): IUser | IUser[] {
  if (typeof result === "string") {
    // Risultato singolo
    return hydrateUserProperties(denormalize(result, userEntity, entities));
  } else {
    // Risultato multiplo
    return denormalize(result, [userEntity], entities).map(
      hydrateUserProperties
    );
  }
}

export interface Course {
  id: string;
  name: string;
  description?: string;
  dateStart: string;
  dateEnd: string;
  status: string;
  product?: IInvestmentProduct;
  productElementaryClass?: IInvestmentProduct;
  userCourse?: UserCourse;
}

export interface UserCourse {
  id: string;
  userFiscalCode: string;
  courseName: string;
  dateQualification: string;
}
