import {lazy} from "react";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'bene-marketing-list'  */ "./BeneMarketingsListPage/BeneMarketingListPage"
    )
);
const NewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'bene-marketing-new' */ "./NewBeneMarketingPage"
    )
);
const ViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'bene-marketing-view' */ "./ViewBeneMarketingPage/ViewBaneMarketingPage"
    )
);

const routes: IRoutes = [
  {
    children: <ListPage />,
    name: "bene-marketing-list",
    path: "/bene-marketing",
    permission: "bene.marketing.read",
    // company: "101",
    // Ulteriore controllo nel componente
  },
  {
    children: <NewPage />,
    name: "bene-marketing-new",
    path: "/bene-marketing/new",
    permission: "bene.marketing.manage",
    company: "101",
  },
  {
    children: <ViewPage />,
    name: "bene-marketing-view",
    path: "/bene-marketing/:id",
    permission: "bene.marketing.read",
  },
];

export default routes;
