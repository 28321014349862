import filter from "lodash/filter";
import {authActions, authSelectors} from ".";
import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {IUploadIdFormData} from "../../Caps/NewCapPage/ClosingSection/IdentificationDrawer/UploadIdForm";
import {IRootState} from "../../redux/reducers";
import {IAppDispatch} from "../../redux/store";
import {isApiError} from "../../types/IApiError";
import {Course, IPrivacySnapshot, UserDB, IUserEntity} from "../../Users/types";
import {IActivateFormData} from "../ActivateForm";
import {IActivateWithTokenFormData} from "../ActivateWithTokenForm";
import {emptyAuth} from "../consts";
import {ILoginFormData} from "../LoginForm";
import {ILostPasswordFormData} from "../LostPasswordForm";
import {IPrivacyFormData} from "../PrivacyForm";
import {
  ICompaniesName,
  IPermissionName,
  IPrivacy,
  IPrivacyQuestion,
  IPrivacySections,
} from "../types";

interface LoginData {
  coursesHistory: Course[];
  permissions: IPermissionName[];
  user: UserDB | null;
  userCompanies: ICompaniesName[];
}

interface PingData {
  coursesHistory: Course[];
  permissions: IPermissionName[];
  user: UserDB | null;
  userCompanies: ICompaniesName[];
}

interface ProfileData {
  user: UserDB;
}

interface RawGetLastPrivacyData {
  privacy: IPrivacy;
  privacySubscription: IPrivacy;
}
interface GetLastPrivacyData {
  lastPrivacy: IPrivacy;
  lastPrivacySubscription: IPrivacy;
}

interface PrivacyData {
  user: UserDB;
}

interface RawActivateData {
  email: string;
}
interface ActivateData {
  activateEmail: string;
}

interface RawLostPassword {
  email: string;
}
interface LostPassword {
  lostPasswordEmail: string;
}

interface UploadIdData {
  user: UserDB;
}

// Può diventare un Thunk?
export async function updateAuthData(
  state: IRootState,
  queryFulfilled: Promise<{data: Partial<PingData>}>,
  dispatch: IAppDispatch
) {
  const {user: currentUser} = authSelectors.selectPingData(state);
  const {
    data: {user, permissions, userCompanies, coursesHistory},
  } = await queryFulfilled;

  console.log(
    "debug update ping",
    JSON.parse(JSON.stringify(currentUser)),
    JSON.parse(JSON.stringify(user))
  );

  // Se user è null -> logout
  if (user === null) {
    console.log("debug update ping", "user è null faccio logout");
    dispatch(
      authApi.util.updateQueryData("ping", undefined, () => {
        return emptyAuth;
      })
    );
    dispatch(authActions.loggedOut());
    setTimeout(() => {
      // Aspettiamo un rendering in modo da aver la nuova pagina visualizzata prima di resettare la cache api
      dispatch(
        api.util.invalidateTags(filter(Tags, (tag) => tag !== Tags.Session))
      );
    }, 10);
    return;
  }

  // Ho gli utenti e sono cambiati -> Invalido la sessione
  if (user && currentUser && user.usid !== currentUser.usid) {
    console.log(
      "debug update ping",
      "ho gli utenti ma son diversi, invalido session"
    );
    dispatch(api.util.invalidateTags([Tags.Session]));
    return;
  }

  // ho l'utente nuovo ma non il vecchio -> login
  if (!currentUser && user) {
    console.log(
      "debug update ping",
      "ho l'utente nuovo ma non il vecchio quindi e un login"
    );
    dispatch(authActions.loggedIn());
  }

  console.log(
    "debug update ping",
    "Aggiorno i dati dell'utente",
    JSON.parse(
      JSON.stringify({
        ...(user && {user}),
        ...(permissions && {permissions}),
        ...(userCompanies && {userCompanies}),
        ...(coursesHistory && {coursesHistory}),
      })
    )
  );
  // Aggiorno i dati dell'utente
  dispatch(
    authApi.util.updateQueryData("ping", undefined, (authState) => {
      console.log(
        "debug update ping",
        "Dati aggiornati:",
        JSON.parse(
          JSON.stringify({
            ...authState,
            ...(user && {user}),
            ...(permissions && {permissions}),
            ...(userCompanies && {userCompanies}),
            ...(coursesHistory && {coursesHistory}),
          })
        )
      );
      return {
        ...authState,
        ...(user && {user}),
        ...(permissions && {permissions}),
        ...(userCompanies && {userCompanies}),
        ...(coursesHistory && {coursesHistory}),
      };
    })
  );

  // Se user non è undefined {
  //   Se usid cambiato {
  //     il vecchio era undefined {
  //       Aggiorno dati ping (login)
  //     } else {
  //       invalido tag
  //     }
  //   } else {
  //     Aggiorno dati ping
  //   }
  // }
  // Aggiorno dati ping (tranne user che sarà undefined)

  // Unire i tre casi in cui aggiorno i dati del ping
  // se user non è undefined && vecchio non è undefined && è cambiato
}

function createJsonPrivacy({
  values,
  lastPrivacy,
}: {
  values: IPrivacyFormData;
  lastPrivacy: IPrivacy | undefined;
}) {
  const lastPrivacySections = (
    lastPrivacy ? JSON.parse(lastPrivacy.json) : []
  ) as IPrivacySections;
  const lastPrivacyQuestions = lastPrivacySections.reduce(
    (questions, section) => {
      return questions.concat(section.questions);
    },
    [] as IPrivacyQuestion[]
  );

  const newPrivacy: Partial<IPrivacySnapshot> = {
    version: lastPrivacy?.version,
    values: lastPrivacyQuestions.reduce(
      (obj, question) => ({
        ...obj,
        [question.name]: !!values[question.name],
      }),
      {}
    ) as unknown as {[Key: string]: boolean},
  };

  return JSON.stringify(newPrivacy);
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginData, ILoginFormData>({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
      async onQueryStarted(_user, {dispatch, queryFulfilled, getState}) {
        try {
          await updateAuthData(
            getState() as IRootState,
            queryFulfilled,
            dispatch
          );
        } catch (response) {
          const error = (response as any).error ?? response;
          if (isApiError(error)) {
            if (error?.code === 901) {
              // Sono già loggato
              //  La var `error` conterrà i dati dell'utente
              console.log("sono già loggato", error);
              dispatch(
                authApi.util.updateQueryData("ping", undefined, (authState) => {
                  return {
                    ...authState,
                    ...{
                      permissions: (error as any).permissions,
                      userCompanies: (error as any).userCompanies,
                      user: (error as any).user,
                      coursesHistory: (error as any).coursesHistory,
                    },
                  };
                })
              );
            }
          }
        }
      },
    }),
    logout: builder.mutation<{}, void>({
      query: () => ({
        url: "logout",
        method: "GET",
      }),
      async onQueryStarted(_user, {dispatch, queryFulfilled, getState}) {
        try {
          await updateAuthData(
            getState() as IRootState,
            queryFulfilled,
            dispatch
          );
        } catch (response) {}
      },
    }),
    ping: builder.query<PingData, void>({
      query: () => "ping",
      providesTags: [Tags.Session],
      transformResponse({
        user = null,
        permissions = [],
        userCompanies = [],
        coursesHistory = [],
      }: SuccessServerResponse<Partial<PingData>>) {
        return {user, permissions, userCompanies, coursesHistory};
      },
      async onQueryStarted(_user, {dispatch, queryFulfilled}) {
        try {
          const {
            data: {user},
          } = await queryFulfilled;
          dispatch(user ? authActions.loggedIn() : authActions.loggedOut());
        } catch {}
      },
    }),
    profile: builder.mutation<ProfileData, Partial<IUserEntity>>({
      query: (body) => ({
        url: "profile",
        method: "POST",
        body,
      }),
      transformResponse({user}: SuccessServerResponse<ProfileData>) {
        return {user};
      },
      async onQueryStarted(_user, {dispatch, queryFulfilled, getState}) {
        await updateAuthData(
          getState() as IRootState,
          queryFulfilled,
          dispatch
        );
      },
    }),
    getLastPrivacy: builder.query<GetLastPrivacyData, void>({
      query: () => "get-last-privacy",
      providesTags: [Tags.Privacy],
      transformResponse({
        privacy,
        privacySubscription,
      }: SuccessServerResponse<RawGetLastPrivacyData>) {
        return {
          lastPrivacy: privacy,
          lastPrivacySubscription: privacySubscription,
        };
      },
    }),
    privacy: builder.mutation<
      PrivacyData,
      {values: IPrivacyFormData; lastPrivacy: IPrivacy | undefined}
    >({
      query: (body) => {
        const jsonPrivacy = createJsonPrivacy(body);
        return {
          url: "privacy",
          method: "POST",
          body: {jsonPrivacy},
        };
      },
      transformResponse({user}: SuccessServerResponse<PrivacyData>) {
        return {user};
      },
      async onQueryStarted(_user, {dispatch, queryFulfilled, getState}) {
        await updateAuthData(
          getState() as IRootState,
          queryFulfilled,
          dispatch
        );
      },
    }),
    activate: builder.mutation<ActivateData, IActivateFormData>({
      query: (body) => ({
        url: "activate-user",
        method: "POST",
        body,
      }),
      transformResponse({email}: SuccessServerResponse<RawActivateData>) {
        return {activateEmail: email};
      },
    }),
    activateWithToken: builder.mutation<void, IActivateWithTokenFormData>({
      query: (body) => ({
        url: "set-password-activate",
        method: "POST",
        body,
      }),
    }),
    lostPassword: builder.mutation<LostPassword, ILostPasswordFormData>({
      query: (body) => ({
        url: "reset-password",
        method: "POST",
        body,
      }),
      transformResponse({email}: SuccessServerResponse<RawLostPassword>) {
        return {lostPasswordEmail: email};
      },
    }),
    lostPasswordWithToken: builder.mutation<void, IActivateWithTokenFormData>({
      query: (body) => ({
        url: "set-password",
        method: "POST",
        body,
      }),
    }),
    uploadId: builder.mutation<UploadIdData, IUploadIdFormData>({
      query: (body) => ({
        url: "identificate",
        method: "POST",
        body,
      }),
      transformResponse({user}: SuccessServerResponse<PrivacyData>) {
        return {user};
      },
      async onQueryStarted(_user, {dispatch, queryFulfilled, getState}) {
        await updateAuthData(
          getState() as IRootState,
          queryFulfilled,
          dispatch
        );
      },
    }),
  }),
});
