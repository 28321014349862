import {z} from "zod";
import {Cap, CapDB, parseCapDB} from "../Caps/types";
import {User, UserDB} from "../Users/types";
import {IRCFormData} from "./shared/ChoseProductLevel/Rc";
import {IBeneLevel} from "./shared/NewBeneMarketingForm";

export interface BeneMarketingDB {
  cap: CapDB;
  contractor: UserDB;
  date: string;
  dateSendRequest?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  jsonData?: string;
  jsonDatesSendEmail?: string;
  jsonDocs?: string;
  jsonProduct: string;
  jsonUrlSetInformativo: string;
  jsonUser: string;
  profitCenter?: string;
  status: BeneMarketingStatus;
  user: UserDB;
}

export interface BeneMarketing {
  cap: Cap;
  contractor: User;
  date: string;
  dateSendRequest?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  data?: DataSnapshot;
  datesSendEmail?: DatesSendEmailSnapshot;
  docs?: Docs;
  product: ProductSnapshot;
  jsonUrlSetInformativo: string;
  jsonUser: string;
  profitCenter?: string;
  status: BeneMarketingStatus;
  user: User;
}

export const parseBeneMarketingDB = (beneMarketingGeneric: BeneMarketingDB) => {
  const {cap, jsonData, jsonProduct, jsonDatesSendEmail, jsonDocs, ...rest} =
    beneMarketingGeneric;
  const beneMarketing: BeneMarketing = {
    ...rest,
    cap: parseCapDB(cap),
    data: jsonData ? (JSON.parse(jsonData) as DataSnapshot) : undefined,
    product: JSON.parse(jsonProduct) as ProductSnapshot,
    datesSendEmail: jsonDatesSendEmail
      ? (JSON.parse(jsonDatesSendEmail) as DatesSendEmailSnapshot)
      : undefined,
    docs: jsonDocs ? (JSON.parse(jsonDocs) as Docs) : undefined,
  };

  return beneMarketing;
};

export enum BeneMarketingStatus {
  Disabled = "0",
  Enabled = "1",
  Intermediate = "2",
}

interface Docs {
  flagReadAllegato3: string;
  flagReadAllegato4Ter: string;
  flagReadAllegato4: string;
  flagReadAvvenutaConsegna: string;
  flagReadSetInformativo: string;
  flagReadDichiarazioneCoerenza: string;
}

export type DatesSendEmailSnapshot = Array<{date: string}>;

export type DataSnapshot = Array<{
  outputAccessoProdotto?: {
    uid: string | null;
    outcome?: {
      messages: string[] | null;
    };
  };
  date: string;
}>;

export enum BeneMarketingTypes {
  House = "house",
  Injuries = "injuries",
  Rc = "rc",
}

export const beneMarketingTypeLabel = {
  house: "Bene Casa",
  injuries: "Bene Infortuni",
  rc: "Bene Professional",
} as const;

export interface ProductSnapshot {
  type: BeneMarketingTypes;
  level: IBeneLevel;
  rc: IRCFormData;
}

export const beneMarketingSearchParamsSchema = z.object({
  contractor: z.string().optional(),
  date: z.string().optional(),
  limit: z
    .string()
    .default("50")
    .transform((str) => parseInt(str, 10)),
  offset: z
    .string()
    .default("0")
    .transform((str) => parseInt(str, 10)),
  sort: z.string().default("-date"),
  user: z.string().optional(),
  productType: z.enum(["house", "injuries", "rc"]).optional(),
});
export type BeneMarketingsParams = z.infer<
  typeof beneMarketingSearchParamsSchema
>;
