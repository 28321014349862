import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import * as Sentry from "@sentry/browser";
import errors, {ErrorCodes} from "../../helpers/errors";
import {IRootState} from "../../redux/reducers";
import IApiError from "../../types/IApiError";
import {emptyAuth} from "../../User/consts";
import {updateAuthData} from "../../User/slice/authApi";

const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

export type SuccessServerResponse<T extends {}> = T & {
  status: "ok";
  message?: string;
};
export type ErrorServerResponse<E extends {}> = {
  status: "ko";
  error: {code: number; message: string} & E;
};
export type ServerResponse<
  T extends {} = Record<string, string | number>,
  E extends {} = Record<string, string | number>
> = SuccessServerResponse<T> | ErrorServerResponse<E>;

export enum Tags {
  Session = "session",
  CoursesHistory = "coursesHistory",
  Privacy = "privacy",
  User = "user",
  ElementaryClass = "elementaryClass",
  BeneProposal = "beneProposal",
  BeneMarketing = "beneMarketing",
  Recommendation = "recommendation",
  Proposal = "proposal",
  ProposalProductDocuments = "proposalProductDocuments",
  Cap = "cap",
  Copy = "copy",
  City = "city",
  Company = "company",
  Option = "option",
  Analytics = "analytics",
}

const fixPostBody = (fetchArgs: string | FetchArgs) => {
  if (typeof fetchArgs === "string") {
    return fetchArgs;
  }

  if (fetchArgs.method !== "POST") {
    return fetchArgs;
  }

  if (!fetchArgs.body) {
    return fetchArgs;
  }

  const formData = new FormData();
  Object.entries(fetchArgs.body as Record<string, any>)
    .filter(([key, value]) => value !== undefined)
    .forEach(([key, value]) => {
      formData.append(key, value);
    });
  return {...fetchArgs, body: formData};
};

const handleRTKErrors = (error: FetchBaseQueryError) => {
  if (error.status === "FETCH_ERROR") {
    return {error: errors[ErrorCodes.FAILED_TO_FETCH]};
  }
  if (error.status === "PARSING_ERROR") {
    if (process.env.NODE_ENV !== "development") {
      Sentry.withScope((scope) => {
        scope.setExtra("Server response", error.data ?? "No global response");
        scope.setExtra("Original error", error.error ?? "No original error");
        scope.setExtra(
          "Original status",
          error.originalStatus ?? "No original status"
        );
        Sentry.captureException(error);
      });
    }
    return {error: errors[ErrorCodes.GENERIC_SERVER_ERROR]};
  }
  if (error.status === "CUSTOM_ERROR") {
    return {error: errors[ErrorCodes.GENERIC_SERVER_ERROR]};
  }

  return {error: errors[ErrorCodes.GENERIC_SERVER_ERROR]};
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseApiUrl,
  credentials: "include",
});

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  IApiError,
  {},
  FetchBaseQueryMeta
> = async (args, baseQueryApi, extraOptions) => {
  const fixedArgs = fixPostBody(args);
  const response = await baseQuery(fixedArgs, baseQueryApi, extraOptions);

  if (response.error) {
    return handleRTKErrors(response.error);
  }

  const serverResponse = response.data as ServerResponse;

  if (serverResponse.status !== "ok") {
    if (serverResponse.error && serverResponse.error.code === 401) {
      // Se ho un errore di sessione elimino i dati in auth
      await updateAuthData(
        baseQueryApi.getState() as IRootState,
        Promise.resolve({data: emptyAuth}),
        baseQueryApi.dispatch
      );
    }
    return {
      error: serverResponse.error as IApiError,
    };
  }

  return {
    data: serverResponse,
  };
};

export function providesTagList<R extends {id: string}[]>(
  resultsWithIds: R | undefined,
  tagType: Tags
) {
  return resultsWithIds
    ? [
        {type: tagType, id: "LIST"},
        ...resultsWithIds.map(({id}) => ({type: tagType, id})),
      ]
    : [{type: tagType, id: "LIST"}];
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: Object.values(Tags),
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
});
