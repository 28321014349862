import {skipToken} from "@reduxjs/toolkit/query";
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {emptyAuth} from "../User/consts";
import {authApi} from "../User/slice";
import {usersApi} from "../Users/slice";
import InsertPhoneForm from "./InsertPhoneForm";
import RequestOTPForm from "./RequestOTPForm";
import {eSignsActions, eSignsSelectors} from "./slice";

const RequestOTPModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(eSignsSelectors.selectIsModalOpen);
  const isUpdatingPhone = useAppSelector(eSignsSelectors.selectIsUpdatingPhone);
  const inPlaceOfContractorFromSlice = useAppSelector(
    eSignsSelectors.selectInPlaceOfContractor
  );

  const {data: {user: loggedUser} = emptyAuth} = authApi.usePingQuery();
  const {data: inPlaceOfContractorFromDB} = usersApi.useGetUserQuery(
    inPlaceOfContractorFromSlice?.id ?? skipToken
  );

  const inPlaceOfContractor =
    inPlaceOfContractorFromDB ?? inPlaceOfContractorFromSlice;

  const openEditNumberForm = () => {
    dispatch(eSignsActions.editNumberFormOpened());
  };
  const closeEditNumberForm = () => {
    dispatch(eSignsActions.editNumberFormClosed());
  };

  const cancelESign = () => {
    dispatch(eSignsActions.modalClosed());
  };

  if (!loggedUser) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} toggle={cancelESign} backdrop="static" centered>
      <ModalBody>
        {(inPlaceOfContractor || loggedUser).personalPhone &&
        !isUpdatingPhone ? (
          <RequestOTPForm
            cancelESign={cancelESign}
            forContractor={
              !!inPlaceOfContractor && inPlaceOfContractor.id !== loggedUser.id
            }
            isModalOpen={isModalOpen}
            signingUser={inPlaceOfContractor || loggedUser}
            openEditNumberForm={openEditNumberForm}
          />
        ) : (
          <InsertPhoneForm
            forContractor={
              !!inPlaceOfContractor && inPlaceOfContractor.id !== loggedUser.id
            }
            cancelESign={cancelESign}
            initialValues={{
              personalPhone:
                isUpdatingPhone &&
                (inPlaceOfContractor || loggedUser).personalPhone
                  ? (inPlaceOfContractor || loggedUser).personalPhone
                  : (inPlaceOfContractor || loggedUser).businessPhone,
            }}
            signingUser={inPlaceOfContractor || loggedUser}
            closeEditNumberForm={closeEditNumberForm}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RequestOTPModal;
