import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {authApi} from "../User/slice";
import {ENABLE_DEV_TOOLS} from "./consts";
import getCustomMiddleware from "./getCustomMiddleware";
import {rootReducer} from "./reducers";

export type IAppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: {ignoredPaths: ["form"]}}).concat(
      getCustomMiddleware()
    ),
  devTools: ENABLE_DEV_TOOLS,
});

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
}

setupListeners(store.dispatch);
store.dispatch(authApi.endpoints.ping.initiate());
store.dispatch(authApi.endpoints.getLastPrivacy.initiate());

export default store;
