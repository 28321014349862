import format from "date-fns/format";
import it from "date-fns/locale/it";
import parse from "date-fns/parse";

export {default as parseISO} from "date-fns/parseISO";
export {default as isAfter} from "date-fns/isAfter";

export const DB_DATE = "yyyy-MM-dd";
export const IT_FORMAT = "dd-MM-yyyy";
export const DB_DATETIME = "yyyy-MM-dd HH:mm:ss";
export const IT_DATETIME = "dd-MM-yyyy HH:mm:ss";
export const LOCALIZED = "P"; // "March 16th, 2021" - "16 marzo 2021";
export const LONG_LOCALIZED = "PPP"; // "March 16th, 2021" - "16 marzo 2021";
export const LONGER_LOCALIZED = "PPPP"; // "Friday, April 29th, 2022" - "venerdì, 29 aprile 2022";
export const LONG_TIME_LOCALIZED = "PPP' alle 'p"; // "March 16th, 2021" - "16 marzo 2021";

export function formatShort(date: string | Date): string {
  return format(new Date(date), LOCALIZED, {locale: it});
}
export function formatWithMonth(date: string | Date): string {
  return format(new Date(date), LONG_LOCALIZED, {locale: it});
}
export function formatWithMonthAndTime(date: string | Date): string {
  return format(new Date(date), LONG_TIME_LOCALIZED, {locale: it});
}
export function formatWithMonthAndDay(date: string | Date): string {
  return format(new Date(date), LONGER_LOCALIZED, {locale: it});
}
export function formatDateTimeDB(date: string | Date = new Date()): string {
  return format(new Date(date), DB_DATETIME);
}

export function parseWithMonth(date: string): Date {
  return parse(date, LONG_LOCALIZED, new Date(), {locale: it});
}

export function itaToDb(dateString: string): string {
  const escapedDateString = dateString.replace(/[\\/-]/g, "-");
  return format(parse(escapedDateString, IT_FORMAT, new Date()), DB_DATE);
}

export function dbToIta(dateString: string): string {
  // Splitto e prendo solo la data (escludo l'ora)
  const escapedDateString = dateString.replace(/[\\/-]/g, "-").split(" ")[0];

  return format(parse(escapedDateString, DB_DATE, new Date()), IT_FORMAT);
}
