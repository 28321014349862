import {api, SuccessServerResponse} from "../../App/slice";
import {Ticket} from "../types";

const apiUrl = "tickets";

interface CreateTicketData {
  ticket: Ticket;
}

export const ticketsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTicket: builder.mutation<
      Ticket,
      Omit<Ticket, "attachment" | "id"> & {attachment: File}
    >({
      query: (data) => ({
        url: apiUrl,
        method: "POST",
        body: data,
      }),
      transformResponse: ({
        ticket,
      }: SuccessServerResponse<CreateTicketData>) => {
        return ticket;
      },
    }),
  }),
});
