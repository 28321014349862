import {useState, VFC} from "react";
import {connect} from "react-redux";
import {NavLink as RRNavLink} from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar as RSNavbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import {IRootState} from "../redux/reducers";
import NewTicketModal from "../Tickets/NewTicketModal";
import {emptyAuth} from "../User/consts";
import {authApi, authSelectors} from "../User/slice";
import "./NavBar.scss";
import {actions, selectors} from "./slice";

interface IStateProps {
  isOpen: boolean;
  hasCoursesNotification: boolean;
}
interface IDispatchProps {
  toggleMenu: () => void;
}

type IProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  isOpen: selectors.selectIsNavOpen(state),
  hasCoursesNotification: authSelectors.selectHasCoursesNotification(state),
});

const mapDispatchToProps = {
  toggleMenu: () => actions.toggleNav(),
};

const Navbar: VFC<IProps> = (props) => {
  const [isNewTicketOpen, setIsNewTicketOpen] = useState(false);
  const [logout] = authApi.useLogoutMutation();

  const toggleNewTicket = () => {
    setIsNewTicketOpen((prevState) => !prevState);
  };

  const {data: {permissions, userCompanies, user} = emptyAuth} =
    authApi.usePingQuery();

  const logoutClickHandler = () => {
    logout();
  };

  return (
    <RSNavbar
      container
      expand="lg"
      className="mb-4"
      color="white"
      light
      fixed="top"
    >
      <NavbarBrand tag={RRNavLink} to="/">
        <img
          src="/img/logo-header.svg"
          height="30"
          alt={`${process.env.REACT_APP_TITLE} logo`}
        />
      </NavbarBrand>
      <NavbarToggler onClick={props.toggleMenu} />
      <Collapse isOpen={props.isOpen} navbar>
        <Nav className="ms-auto" navbar>
          {permissions && permissions.includes("cap.read") ? (
            <NavItem>
              <NavLink to="/analisi" tag={RRNavLink}>
                Analisi
              </NavLink>
            </NavItem>
          ) : (
            permissions?.includes("cap.contractor-read") && (
              <NavItem>
                <NavLink to="/analisi" tag={RRNavLink}>
                  Le tue analisi
                </NavLink>
              </NavItem>
            )
          )}
          {permissions?.includes("recommendation.read") ||
          permissions?.includes("recommendations.read") ? (
            <NavItem>
              <NavLink to="/raccomandazioni" tag={RRNavLink}>
                Raccomandazioni
              </NavLink>
            </NavItem>
          ) : (
            permissions?.includes("recommendation.contractor-read") && (
              <NavItem>
                <NavLink to="/raccomandazioni" tag={RRNavLink}>
                  Le tue raccomandazioni
                </NavLink>
              </NavItem>
            )
          )}
          {(userCompanies?.includes("99") &&
            permissions?.includes("proposal.read")) ||
          permissions?.includes("proposals.read") ? (
            <NavItem>
              <NavLink to="/proposte" tag={RRNavLink}>
                Proposte
              </NavLink>
            </NavItem>
          ) : (
            permissions?.includes("proposal.contractor-read") && (
              <NavItem>
                <NavLink to="/proposte" tag={RRNavLink}>
                  Le tue proposte
                </NavLink>
              </NavItem>
            )
          )}
          {permissions.includes("bene.marketing.manage") &&
          permissions?.includes("bene.manage") &&
          userCompanies?.includes("101") ? (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Bene Assicurazioni
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={RRNavLink} to="/proposte-bene">
                  Polizze
                </DropdownItem>
                <DropdownItem tag={RRNavLink} to="/bene-marketing">
                  Bene Marketing
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : permissions.includes("bene.marketing.manage") ? (
            <NavItem>
              <NavLink to="/bene-marketing" tag={RRNavLink}>
                Bene Marketing
              </NavLink>
            </NavItem>
          ) : permissions?.includes("bene.manage") &&
            userCompanies?.includes("101") ? (
            <NavItem>
              <NavLink to="/proposte-bene" tag={RRNavLink}>
                Bene Assicurazioni
              </NavLink>
            </NavItem>
          ) : permissions?.includes("bene.contractor-read") ? (
            <NavItem>
              <NavLink to="/proposte-bene" tag={RRNavLink}>
                Bene Assicurazioni
              </NavLink>
            </NavItem>
          ) : null}
          {permissions?.includes("elementary_class.read") ||
          permissions?.includes("elementary_classes.read") ? (
            <NavItem>
              <NavLink to="/tutele" tag={RRNavLink}>
                Tutele
              </NavLink>
            </NavItem>
          ) : (
            permissions?.includes("elementary_class.contractor-read") && (
              <NavItem>
                <NavLink to="/tutele" tag={RRNavLink}>
                  Le tue tutele
                </NavLink>
              </NavItem>
            )
          )}
          {permissions?.includes("users.manage") && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Utenti
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={RRNavLink} to="/users">
                  Elenco utenti
                </DropdownItem>
                <DropdownItem tag={RRNavLink} to="/users/create-bulk">
                  Carica Advisor con CSV
                </DropdownItem>
                <DropdownItem tag={RRNavLink} to="/users/update-bulk">
                  Modifica Advisor con CSV
                </DropdownItem>
                <DropdownItem tag={RRNavLink} to="/users/delete-bulk">
                  Disattiva Advisor con CSV
                </DropdownItem>
                <DropdownItem tag={RRNavLink} to="/users/upload-courses">
                  Carica corsi con CSV
                </DropdownItem>
                <DropdownItem
                  href={`${process.env.REACT_APP_API_BASE_URL}users/download`}
                >
                  Scarica lista Advisor
                </DropdownItem>
                <DropdownItem
                  href={`${process.env.REACT_APP_API_BASE_URL}users/disable-download`}
                >
                  Scarica lista Advisor Inattivi
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {user ? (
            <>
              {permissions.includes("stats.read") ? (
                <NavItem>
                  <NavLink to={`/statistiche/${user?.id}`} tag={RRNavLink}>
                    Statistiche
                  </NavLink>
                </NavItem>
              ) : null}
              {permissions?.includes("ticket.manage") && (
                <>
                  <NavItem>
                    <NavLink href="#" onClick={toggleNewTicket}>
                      Segnalazione
                    </NavLink>
                  </NavItem>
                  <NewTicketModal
                    isOpen={isNewTicketOpen}
                    toggle={toggleNewTicket}
                  />
                </>
              )}
              <UncontrolledDropdown nav>
                <DropdownToggle nav caret>
                  {props.hasCoursesNotification &&
                    permissions?.includes("cap.manage") && (
                      <span className="badge badge-pill badge-warning" />
                    )}
                  Il tuo account
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={RRNavLink} to="/user/profile">
                    Gestione del profilo
                  </DropdownItem>
                  {permissions?.includes("cap.contractor-manage") && (
                    <>
                      <DropdownItem tag={RRNavLink} to="/user/privacy">
                        Gestione del consenso
                      </DropdownItem>
                    </>
                  )}
                  {permissions?.includes("cap.manage") && (
                    <DropdownItem tag={RRNavLink} to="/user/qualifications">
                      {props.hasCoursesNotification && (
                        <span className="badge badge-pill badge-warning" />
                      )}
                      Abilitazioni
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={logoutClickHandler}>
                    Esci dall'area riservata
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ) : (
            <NavItem>
              <NavLink to="/login" tag={RRNavLink}>
                Entra nell'area riservata
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </RSNavbar>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
