import {lazy} from "react";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'elementary-classes-list'  */ "./ElementaryClassesListPage"
    )
);
const NewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'elementary-classes-new' */ "./NewElementaryClassPage/NewElementaryClassPage"
    )
);
const ViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'elementary-classes-view' */ "./ViewElementaryClassPage/ViewElementaryClassPage"
    )
);

const routes: IRoutes = [
  {
    children: <ListPage />,
    name: "tutele-list",
    path: "/tutele",
    permission:
      "elementary_class.read||elementary_classes.read||elementary_class.contractor-read",
  },
  {
    children: <NewPage />,
    name: "tutele-new",
    path: "/tutele/new",
    permission: "elementary_class.manage",
  },
  {
    children: <ViewPage />,
    name: "tutele-view",
    path: "/tutele/:id",
    permission:
      "elementary_class.read||elementary_classes.read||elementary_class.manage||elementary_class.approve||elementary_class.contractor-read",
  },
];

export default routes;
