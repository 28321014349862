import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {IAdvisorTree, ILoadAdvisorsParams, IRoots} from "../types";

const apiUrl = "analytics";

interface LoadAdvisorTreeResponse {
  tree: IAdvisorTree;
}
interface LoadAdvisorTreeResponse {
  roots: IRoots[];
}

export const analyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loadAdvisorsTree: builder.query<IAdvisorTree, ILoadAdvisorsParams>({
      query: ({id, fromDate, toDate}) => ({
        url: `${apiUrl}/tree/${id}`,
        params: {
          from_date: fromDate,
          to_date: toDate,
        },
      }),
      transformResponse: ({
        tree,
      }: SuccessServerResponse<LoadAdvisorTreeResponse>) => {
        return tree;
      },
      providesTags: [{type: Tags.Analytics, id: "TREE"}],
    }),
    loadRoots: builder.query<IRoots[], void>({
      query: () => ({
        url: `${apiUrl}/roots`,
      }),
      transformResponse: ({
        roots,
      }: SuccessServerResponse<{roots: IRoots[]}>) => {
        return roots;
      },
      providesTags: [{type: Tags.Analytics, id: "ROOTS"}],
    }),
  }),
});
