import {z} from "zod";
import {
  IAssociatedOption,
  CapDB,
  IInvestmentOption,
  Cap,
  parseCapDB,
} from "../Caps/types";
import {UserDB, parseUserDB, User} from "../Users/types";

export interface RecommendationDB {
  cap: CapDB;
  code: string;
  comment: string;
  contractor: UserDB;
  date: string;
  esignId?: string;
  userEsignId?: string;
  id: string;
  jsonDocs?: string;
  jsonOptions: string;
  jsonRevisions?: string;
  jsonRiskProfile?: string;
  status: RecommendationStatus;
  user: UserDB;
  version: string;
}

export interface Recommendation {
  cap: Cap;
  code: string;
  comment: string;
  contractor: User;
  date: string;
  esignId?: string;
  userEsignId?: string;
  id: string;
  docs?: RecommendationDocs;
  options: Options;
  revisions: Revision[];
  riskProfile?: RiskProfile;
  status: RecommendationStatus;
  user: User;
  version: string;
}

export const parseRecommendationDB = (
  recommendationGeneric: RecommendationDB
) => {
  const {cap, contractor, jsonDocs, jsonOptions, jsonRevisions, user, ...rest} =
    recommendationGeneric;
  const recommendation: Recommendation = {
    ...rest,
    cap: parseCapDB(cap),
    contractor: parseUserDB(contractor),
    docs: jsonDocs ? (JSON.parse(jsonDocs) as RecommendationDocs) : undefined,
    options: JSON.parse(jsonOptions) as Options,
    revisions: jsonRevisions ? JSON.parse(jsonRevisions) : [],
    riskProfile: jsonRevisions
      ? (JSON.parse(jsonRevisions) as RiskProfile)
      : undefined,
    user: parseUserDB(user),
  };

  return recommendation;
};

export enum RecommendationStatus {
  STATUS_TO_BE_VALIDATED = "0", // Inviato per l'approvazione
  STATUS_CORRESPONDING = "1", // Congrua
  STATUS_WAIT_REVIEW = "2", // Richiesta revisione
  STATUS_NOT_VALIDATE = "3", // Non validabile
  STATUS_IBIPS_REQUEST = "4", // Richiesto rinnovo IBIPs
  STATUS_REQUEST_VERIFICATION = "5", // Verifica adeguatezza richiesta
  STATUS_REQUEST_ESIGN = "6", // Manca la firma del contractor
  STATUS_REJECTED = "7", // Raccomandazione archiviata (i.e. è stata duplicata ed ora è solo in consultazione)
  STATUS_REQUEST_ESIGN_ADVISOR = "8", // Manca la firma dell'Advisor
}

export interface Revision {
  date: string;
  text: string;
}

export interface RecommendationDocs {
  flagReadAllegato4Bis: string;
  flagReadSetInformativo: string;
  flagReadAllegato3: string;
  flagReadAllegato4Ter: string;
  flagReadRaccomandazione: string;
  flagCompanyNamesList: string;
  urlSetInformativo: string;
  urlCompanyNameList: string;
}

interface RiskProfile {
  flagEsigenzaInvestimento?: "true";
  flagSituazioneFinanziaria?: "true";
  flagTolleranzaRischio?: "true";
  flagConoscenze?: "true";
  flagProdottiLinea?: "true";
}

export interface SelectedOption {
  id: string;
  percent: string;
}

export interface Options {
  options: IInvestmentOption[];
  associatedOptions: IAssociatedOption[];
  selectedOptions: SelectedOption[];
}

export const recommendationsSearchParamsSchema = z.object({
  code: z.string().optional(),
  contractor: z.string().optional(),
  limit: z
    .string()
    .default("50")
    .transform((str) => parseInt(str, 10)),
  offset: z
    .string()
    .default("0")
    .transform((str) => parseInt(str, 10)),
  sort: z.string().default("-date"),
  user: z.string().optional(),
  status: z.nativeEnum(RecommendationStatus).optional(),
});
export type RecommendationsParams = z.infer<
  typeof recommendationsSearchParamsSchema
>;
