import React from "react";
import {Alert, Button, FormGroup, Label} from "reactstrap";
import {Field, Form, InjectedFormProps, reduxForm} from "redux-form";
import {integerNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import {IconSpinner} from "../Icons";
import {authApi} from "../User/slice";
import {usersApi} from "../Users/slice";
import {UserDB} from "../Users/types";

export interface IInsertPhoneFormData {
  personalPhone?: string;
}
interface IInsertPhoneFormErrors {
  personalPhone?: string;
}

interface IOwnProps {
  cancelESign: () => void;
  closeEditNumberForm: () => void;
  forContractor: boolean;
  signingUser: UserDB;
}

type IProps = IOwnProps & InjectedFormProps<IInsertPhoneFormData, IOwnProps>;

const validate = (values: IInsertPhoneFormData) => {
  const errors: IInsertPhoneFormErrors = {};
  if (!values.personalPhone) {
    errors.personalPhone = "Inserisci un numero di cellulare";
  }
  return errors;
};

const InsertPhoneForm: React.FC<IProps> = ({
  cancelESign,
  closeEditNumberForm,
  forContractor,
  handleSubmit,
  signingUser,
}) => {
  const [
    updateProfile,
    {isError: isProfileError, isLoading: isProfileLoading, error: profileError},
  ] = authApi.useProfileMutation();
  const [
    updateUser,
    {
      isError: isUpdateUserError,
      isLoading: isUpdateUserLoading,
      error: updateUserError,
    },
  ] = usersApi.useUpdateUserMutation();

  const addContractorPhone = async (
    contractorId: string,
    values: IInsertPhoneFormData
  ) => {
    await updateUser({
      userId: contractorId,
      updateUser: {personalPhone: values.personalPhone},
    });
  };

  const insertPhoneSubmit = async (values: IInsertPhoneFormData) => {
    if (forContractor) {
      await addContractorPhone(signingUser.id, values);
    } else {
      await updateProfile(values);
    }
    closeEditNumberForm();
  };

  return (
    <Form onSubmit={handleSubmit(insertPhoneSubmit)}>
      {forContractor ? (
        <h3>Inserisci il numero di cellulare del tuo cliente</h3>
      ) : (
        <h3>Inserisci il tuo numero di cellulare</h3>
      )}
      <p>in questo modo potremo inviare l'OTP per firmare il documento</p>
      <FormGroup>
        <Label for="personalPhone">Numero di cellulare</Label>
        <Field
          id="personalPhone"
          name="personalPhone"
          component={RenderField}
          type="text"
          placeholder="Numero di cellulare"
          normalize={integerNormalizer}
        />
      </FormGroup>
      {isProfileError && <Alert color="danger">{profileError?.message}</Alert>}
      {isUpdateUserError && (
        <Alert color="danger">{updateUserError?.message}</Alert>
      )}
      <Button
        color="primary"
        outline
        type="submit"
        disabled={isProfileLoading || isUpdateUserLoading}
      >
        {(isProfileLoading || isUpdateUserLoading) && (
          <IconSpinner className="icon-spin me-2" />
        )}
        Conferma
      </Button>{" "}
      <Button color="secondary" outline type="button" onClick={cancelESign}>
        Annulla
      </Button>
    </Form>
  );
};

export default reduxForm<IInsertPhoneFormData, IOwnProps>({
  form: "insertPhoneForm",
  validate,
})(InsertPhoneForm);
