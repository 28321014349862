import React, {FC, VFC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import analyticsRoutes from "../Analytics/routes";
import beneMarketingsRoutes from "../BeneMarketings/routes";
import beneProposalsRoutes from "../BeneProposals/routes";
import capsRoutes from "../Caps/routes";
import elementaryClassesRoutes from "../ElementaryClasses/routes";
import makeRoutes from "../helpers/makeRoutes";
import proposalsRoutes from "../Proposals/routes";
import recommendationsRoutes from "../Recommendation/routes";
import {useAppSelector} from "../redux/hooks";
import {emptyAuth} from "../User/consts";
import userRoutes from "../User/routes";
import {authApi, authSelectors} from "../User/slice";
import {IPermissionName} from "../User/types";
import usersRoutes from "../Users/routes";
import appRoutes from "./appRoutes";
import {MAINTENANCE_MODE} from "./consts";
import {Layout} from "./Layout";
import MaintenancePage from "./MaintenancePage";
import {NoMenuLayout} from "./NoMenuLayout";
import NotFoundPage from "./NotFoundPage";
import {PageLoading} from "./PageLoading";

export const routes = [
  ...analyticsRoutes,
  ...appRoutes,
  ...beneMarketingsRoutes,
  ...beneProposalsRoutes,
  ...capsRoutes,
  ...elementaryClassesRoutes,
  ...proposalsRoutes,
  ...recommendationsRoutes,
  ...userRoutes,
  ...usersRoutes,
];

interface HomeComponentProps {
  isLogged?: boolean;
  permissions: IPermissionName[];
}

const HomeComponent: VFC<HomeComponentProps> = ({isLogged, permissions}) => {
  if (!isLogged) {
    return <Navigate to="/login" replace />;
  }
  if (
    permissions.includes("cap.read") ||
    permissions.includes("cap.contractor-read")
  ) {
    return <Navigate to="/analisi" replace />;
  }
  if (
    permissions.includes("recommendation.read") ||
    permissions.includes("recommendations.read") ||
    permissions.includes("recommendation.contractor-read")
  ) {
    return <Navigate to="/raccomandazioni" replace />;
  }
  if (permissions.includes("users.manage")) {
    return <Navigate to="/users" replace />;
  }
  return <Navigate to="/user/profile" replace />;
};

const RouterComponent: FC = () => {
  // const {
  //   data: {
  //     user: currentUser,
  //     permissions,
  //     userCompanies: companies,
  //   } = emptyAuth,
  //   isLoading: isPingLoading,
  //   isError: isPingError,
  //   error: pingError,
  // } = useAppSelector(authSelectors.pingSelector);
  const {
    data: {
      user: currentUser,
      permissions,
      userCompanies: companies,
    } = emptyAuth,
    isLoading: isPingLoading,
    isError: isPingError,
    error: pingError,
  } = authApi.usePingQuery();
  const {
    isLoading: isLastPrivacyLoading,
    isError: isLastPrivacyError,
    error: lastPrivacyError,
  } = authApi.useGetLastPrivacyQuery();

  const isLoggedIn = useAppSelector(authSelectors.selectIsLoggedIn);

  if (MAINTENANCE_MODE) {
    return <MaintenancePage />;
  }

  if (isPingError) {
    throw pingError;
  }
  if (currentUser && isLastPrivacyError) {
    throw lastPrivacyError;
  }

  if (isLoggedIn === undefined || isPingLoading || isLastPrivacyLoading) {
    return <PageLoading />;
  }

  const isLogged = isLoggedIn;

  if (!permissions.includes) {
    console.log(
      "Errore include",
      JSON.stringify(permissions, null, 2),
      permissions
    );
  }

  const noMenuLayoutRoutes = routes.filter(
    (route) => route.layout === "noMenu"
  );
  const defaultLayoutRoutes = routes.filter(
    (route) => route.layout === undefined || route.layout === "default"
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeComponent isLogged={isLogged} permissions={permissions} />
        }
      />
      <Route element={<NoMenuLayout />}>
        {makeRoutes({
          routes: noMenuLayoutRoutes,
          companies,
          permissions,
          isLogged,
        })}
      </Route>
      <Route element={<Layout />}>
        {makeRoutes({
          routes: defaultLayoutRoutes,
          companies,
          permissions,
          isLogged,
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RouterComponent;
