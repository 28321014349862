import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {authActions} from "../../User/slice";

type IState = DeepReadonly<{
  chosenCap?: string;
}>;

const beneMarketingsInitialState: IState = {};

const beneMarketingsSlice = createSlice({
  name: "beneMarketings",
  initialState: beneMarketingsInitialState,
  reducers: {
    choseCap: (state, action: PayloadAction<{capId: string | undefined}>) => {
      state.chosenCap = action.payload.capId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.loggedOut, () => {
      return beneMarketingsInitialState;
    });
  },
});

export const actions = beneMarketingsSlice.actions;
export const reducer = beneMarketingsSlice.reducer;
