import {parseUserDB, User, UserDB} from "../Users/types";

export interface ESignDB {
  dateRequestEsign?: string;
  dateRequestTransaction: string;
  esignedFileUrl?: string;
  id: string;
  jsonRequestEsign?: string;
  jsonRequestTransaction: string;
  statusEsign: ESignStatus;
  transactionId: TransactionId;
  user: UserDB;
}

export interface ESign {
  dateRequestEsign?: string;
  dateRequestTransaction: string;
  esignedFileUrl?: string;
  id: string;
  requestEsign?: RequestESignSnapshot;
  requestTransaction: RequestTransactionSnapshot;
  statusEsign: ESignStatus;
  transactionId: TransactionId;
  user: User;
}

export const parseESignDB = (eSignGeneric: ESignDB): ESign => {
  const {jsonRequestEsign, jsonRequestTransaction, user, ...rest} =
    eSignGeneric;
  const eSign: ESign = {
    ...rest,
    requestEsign: jsonRequestEsign
      ? (JSON.parse(jsonRequestEsign) as RequestESignSnapshot)
      : undefined,
    requestTransaction: JSON.parse(
      jsonRequestTransaction
    ) as RequestTransactionSnapshot,
    user: parseUserDB(user),
  };

  return eSign;
};

export interface TransactionId {
  [key: number]: string;
}

type ESignStatus = "0" | "1";

export interface RequestTransactionSnapshot {
  cell: string;
  clientUser: string;
  email: string;
  fiscalCode: string;
  name: string;
  surname: string;
  transactionId: string;
}

export interface RequestESignSnapshot {
  clientUserAruba: string;
  transactionId: string;
  OTP: string;
  extrainfo: unknown;
  pdfType: string;
  pdfName: string;
  referenceId: string;
}

export interface SignFEADocRequestData {
  OTP: string;
  transactionId: string;
  pdfType: PDFTypes;
  referenceId: string;
  [key: string]: string;
}

/**
 * Enum con i tipi di documento, per ogni tipo dobbiamo inviare l'id dell'entità
 *  corrispondente
 * *
 *  * templatePrivacy - referenceId -> ID USER
 *  * templatePrivacyContractor - referenceId -> ID CONTRACTOR
 *  * templatePrivacySubscription - referenceId -> ID USER
 *  * templateIdentificazione - referenceId -> ID CAP
 *  * templateAnalisi - referenceId -> ID CAP
 *  * templateProposta - referenceId -> ID PROPOSTA
 *  * templateRaccomandazione - referenceId -> ID RACCOMANDAZIONE
 *  * templateDichiarazioneCoerenzaBene - referenceId -> ID PROPOSTA BENE
 *  * templateDichiarazioneCoerenza - ID CLASSE ELEMENTARE
 *  * templateDichiarazioneAvvenutaConsegnaBene - referenceId -> ID PROPOSTA BENE
 *  * templateDichiarazioneAvvenutaConsegna - referenceId -> ID CLASSE ELEMENTARE
 */
export enum PDFTypes {
  Privacy = "templatePrivacy",
  PrivacyContractor = "templatePrivacyContractor",
  PrivacySubscription = "templatePrivacySubscription",
  Identification = "templateIdentificazione",
  Cap = "templateAnalisi",
  Proposal = "templateProposta",
  Recommendation = "templateRaccomandazione",
  BeneConsistency = "templateDichiarazioneCoerenzaBene",
  Consistency = "templateDichiarazioneCoerenza",
  BeneDelivery = "templateDichiarazioneAvvenutaConsegnaBene",
  Delivery = "templateDichiarazioneAvvenutaConsegna",
  BeneMarketing = "templateDichiarazioneCoerenzaBeneMarketing",
}
