import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const AddPage = lazy(
  () => import(/* webpackChunkName: 'user-new' */ "./AddUserPage")
);
const CreateBulkPage = lazy(
  () => import(/* webpackChunkName: 'users-create-bulk' */ "./bulks/CreatePage")
);
const DeleteBulkPage = lazy(
  () => import(/* webpackChunkName: 'users-delete-bulk' */ "./bulks/DeletePage")
);
const UpdateBulkPage = lazy(
  () => import(/* webpackChunkName: 'users-update-bulk' */ "./bulks/UpdatePage")
);
const DetailPage = lazy(
  () => import(/* webpackChunkName: 'users-edit' */ "./UserDetailPage")
);
const ListPage = lazy(
  () => import(/* webpackChunkName: 'users' */ "./UsersListPage")
);
const UploadCourses = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-upload-courses' */ "./bulks/UploadCourses"
    )
);

const routes: IRoutes = [
  {
    children: <AddPage />,
    name: "user-new",
    path: "/user/new",
    permission: "users.manage",
  },
  {
    children: <ListPage />,
    name: "users",
    path: "/users",
    permission: "users.manage",
  },
  {
    children: <DetailPage />,
    name: "users-edit",
    path: "/users/edit/:userId",
    permission: "users.manage",
  },
  {
    children: <CreateBulkPage />,
    name: "users-create-bulk",
    path: "/users/create-bulk",
    permission: "users.manage",
  },
  {
    children: <DeleteBulkPage />,
    name: "users-delete-bulk",
    path: "/users/delete-bulk",
    permission: "users.manage",
  },
  {
    children: <UpdateBulkPage />,
    name: "users-update-bulk",
    path: "/users/update-bulk",
    permission: "users.manage",
  },
  {
    children: <UploadCourses />,
    name: "users-upload-courses",
    path: "/users/upload-courses",
    permission: "users.manage",
  },
];

export default routes;
