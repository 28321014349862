import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const NewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'recommendation-new' */ "./NewRecommendationPage"
    )
);
const ViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'recommendation-view' */ "./ViewRecommendationPage"
    )
);
const ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'recommendation-list' */ "./RecommendationsListPage"
    )
);

const routes: IRoutes = [
  {
    children: <NewPage />,
    name: "recommendation-new",
    path: "/raccomandazioni/new",
    permission: "recommendation.manage",
  },
  {
    children: <ViewPage />,
    name: "recommendation-view",
    path: "/raccomandazioni/:recommendationId",
    permission:
      "recommendation.read||recommendations.read||recommendation.manage||recommendation.approve||recommendation.contractor-read",
  },
  {
    children: <ListPage />,
    name: "recommendation-list",
    path: "/raccomandazioni",
    permission:
      "recommendation.read||recommendations.read||recommendation.contractor-read",
  },
];

export default routes;
