import {createSlice} from "@reduxjs/toolkit";
import {IRootState} from "../../redux/reducers";

interface LayoutState {
  isNavOpen: boolean;
}

const layoutSlice = createSlice({
  name: "app",
  initialState: {isNavOpen: false, submenuOpen: null} as LayoutState,
  reducers: {
    openNav: (state) => {
      state.isNavOpen = true;
    },
    closeNav: (state) => {
      state.isNavOpen = false;
    },
    toggleNav: (state) => {
      state.isNavOpen = !state.isNavOpen;
    },
  },
});

export const reducer = layoutSlice.reducer;
export const actions = layoutSlice.actions;
export const selectors = {
  selectIsNavOpen: (state: IRootState) => state.app.isNavOpen,
};
