import {ResizeObserver} from "@juggle/resize-observer";
import React from "react";
import {animated, useSpring} from "react-spring";
import useMeasure from "react-use-measure";
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {ticketsApi} from "../slice";
import NewTicketForm from "./NewTicketForm";
import "./NewTicketModal.scss";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

const NewTicketModal = ({isOpen, toggle}: IProps) => {
  const [, {isSuccess: isCreateTicketSuccess, reset: resetCreateTicket}] =
    ticketsApi.useCreateTicketMutation({fixedCacheKey: "createTicket"});

  const [ref, {height}] = useMeasure({polyfill: ResizeObserver});
  const props = useSpring({height});

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="new-ticket"
      onClosed={resetCreateTicket}
    >
      <ModalHeader toggle={toggle}>
        Segnalazione di problemi tecnici o di errori applicativi
      </ModalHeader>
      <animated.div style={{overflow: "hidden", ...props}}>
        <div ref={ref}>
          {isCreateTicketSuccess ? (
            <ModalBody>
              <Alert color="success" className="mb-0">
                La tua segnalazione è stata correttamente inviata. Verrà presa
                in carico a breve. Potresti essere ricontattato via email in
                caso sia necessario avere maggiori informazioni.
              </Alert>
            </ModalBody>
          ) : (
            <NewTicketForm toggle={toggle} />
          )}
        </div>
      </animated.div>
    </Modal>
  );
};

export default NewTicketModal;
