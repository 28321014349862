import last from "lodash/last";
import moment from "moment/moment";
import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import errors, {ErrorCodes} from "../../helpers/errors";
import {
  BeneMarketing,
  BeneMarketingDB,
  BeneMarketingsParams,
  parseBeneMarketingDB,
} from "../types";

const apiUrl = "bene-marketing-proposals";

interface GetBeneMarketingsResponse {
  benesMarketing: BeneMarketingDB[];
  numBenesMarketing: number;
}
interface GetBeneMarketingsData {
  beneMarketings: BeneMarketing[];
  numBeneMarketings: number;
}

interface CreateBeneMarketingResponse {
  beneMarketing: BeneMarketingDB;
}

interface GetBeneMarketingResponse {
  beneMarketing: BeneMarketingDB;
}

interface SendBeneMarketingToBeneResponse {
  beneMarketing: BeneMarketingDB;
}

export const beneMarketingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBeneMarketings: builder.query<
      GetBeneMarketingsData,
      BeneMarketingsParams
    >({
      query: (params) => ({
        url: apiUrl,
        params,
      }),
      providesTags: (data) =>
        providesTagList(data?.beneMarketings, Tags.BeneMarketing),
      transformResponse: ({
        benesMarketing,
        numBenesMarketing,
      }: SuccessServerResponse<GetBeneMarketingsResponse>) => {
        return {
          beneMarketings: benesMarketing.map(parseBeneMarketingDB),
          numBeneMarketings: numBenesMarketing,
        };
      },
    }),
    createBeneMarketing: builder.mutation<
      BeneMarketing,
      {
        user: string;
        contractor: string;
        cap: string;
        dateEffect: string;
        jsonProduct: string;
      }
    >({
      query: (data) => {
        const dateEffect =
          data.dateEffect &&
          moment(data.dateEffect, "DD/MM/YYYY").format("YYYY-MM-DD");

        return {
          url: apiUrl,
          method: "POST",
          body: {...data, dateEffect},
        };
      },
      transformResponse: ({
        beneMarketing,
      }: SuccessServerResponse<CreateBeneMarketingResponse>) => {
        return parseBeneMarketingDB(beneMarketing);
      },
      invalidatesTags: [{type: Tags.BeneMarketing, id: "LIST"}],
    }),
    getBeneMarketing: builder.query<BeneMarketing, string>({
      query: (beneMarketingId) => ({
        url: `${apiUrl}/${beneMarketingId}`,
      }),
      transformResponse: ({
        beneMarketing,
      }: SuccessServerResponse<GetBeneMarketingResponse>) => {
        return parseBeneMarketingDB(beneMarketing);
      },
      providesTags: (result, error, beneMarketingId) => [
        {type: Tags.BeneMarketing, id: beneMarketingId},
      ],
    }),
    sendBeneMarketingToBene: builder.mutation<BeneMarketing, string>({
      queryFn: async (beneMarketingId, baseQueryApi, options, baseQuery) => {
        const response = await baseQuery({
          url: `${apiUrl}/${beneMarketingId}/send-marketing`,
          method: "POST",
        });

        if (response.error) {
          return {error: response.error};
        }

        const serverResponse =
          response.data as SuccessServerResponse<SendBeneMarketingToBeneResponse>;
        const beneMarketing = parseBeneMarketingDB(
          serverResponse.beneMarketing
        );

        const lastData = last(beneMarketing.data);
        const uid = lastData?.outputAccessoProdotto?.uid;

        if (!uid) {
          // Non abbiamo lo uid
          return {error: errors[ErrorCodes.BENE_UID_NOT_VALID]};
        } else if (
          lastData?.outputAccessoProdotto?.outcome?.messages !== null
        ) {
          // C'è lo uid ma abbiamo cmq degli errori conosciuti
          return {
            error: {
              ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
              message:
                `Bene Assicurazioni ha tornato questo errore: "${lastData?.outputAccessoProdotto?.outcome?.messages[0]}". ` +
                errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
            },
          };
        }

        return {data: beneMarketing};
      },
      invalidatesTags: [{type: Tags.BeneMarketing, id: "LIST"}],
      async onQueryStarted(beneMarketingId, {dispatch, queryFulfilled}) {
        try {
          const {data: beneMarketing} = await queryFulfilled;

          dispatch(
            beneMarketingsApi.util.updateQueryData(
              "getBeneMarketing",
              beneMarketingId,
              () => {
                return beneMarketing;
              }
            )
          );
        } catch {}
      },
    }),
  }),
});
