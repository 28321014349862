import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import {
  ElementaryClass,
  ElementaryClassDB,
  ElementaryClassesParams,
  parseElementaryClassDB,
} from "../types";

const apiUrl = "elementary-classes";

interface GetElementaryClassesResponse {
  numElementaryClasses: number;
  elementaryClasses: ElementaryClassDB[];
}

interface CreateElementaryClassResponse {
  elementaryClass: ElementaryClassDB;
}

interface GetElementaryClassResponse {
  elementaryClass: ElementaryClassDB;
}

interface SendElementaryClassEmailResponse {
  elementaryClass: ElementaryClassDB;
}

export const elementaryClassesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getElementaryClasses: builder.query<
      {
        numElementaryClasses: number;
        elementaryClasses: ElementaryClass[];
      },
      ElementaryClassesParams
    >({
      query: (params) => ({
        url: apiUrl,
        params,
      }),
      providesTags: (data) =>
        providesTagList(data?.elementaryClasses, Tags.ElementaryClass),
      transformResponse: ({
        elementaryClasses,
        numElementaryClasses,
      }: SuccessServerResponse<GetElementaryClassesResponse>) => {
        return {
          elementaryClasses: elementaryClasses.map(parseElementaryClassDB),
          numElementaryClasses,
        };
      },
    }),
    createElementaryClass: builder.mutation<
      ElementaryClass,
      {cap: string; company: string; productElementaryClassId?: string}
    >({
      query: (data) => ({
        url: apiUrl,
        method: "POST",
        body: data,
      }),
      transformResponse: ({
        elementaryClass,
      }: SuccessServerResponse<CreateElementaryClassResponse>) => {
        return parseElementaryClassDB(elementaryClass);
      },
      invalidatesTags: [{type: Tags.ElementaryClass, id: "LIST"}],
    }),
    getElementaryClass: builder.query<ElementaryClass, string>({
      query: (elementaryClassId) => ({
        url: `${apiUrl}/${elementaryClassId}`,
      }),
      transformResponse: ({
        elementaryClass,
      }: SuccessServerResponse<GetElementaryClassResponse>) => {
        return parseElementaryClassDB(elementaryClass);
      },
      providesTags: (result, error, elementaryClassId) => [
        {type: Tags.ElementaryClass, id: elementaryClassId},
      ],
    }),
    sendElementaryClassEmail: builder.mutation<ElementaryClass, string>({
      query: (elementaryClassId) => ({
        url: `${apiUrl}/${elementaryClassId}/send-email`,
        method: "POST",
      }),
      transformResponse: ({
        elementaryClass,
      }: SuccessServerResponse<SendElementaryClassEmailResponse>) => {
        return parseElementaryClassDB(elementaryClass);
      },
      invalidatesTags: [{type: Tags.ElementaryClass, id: "LIST"}],
      async onQueryStarted(elementaryClassId, {dispatch, queryFulfilled}) {
        try {
          const {data: elementaryClass} = await queryFulfilled;

          dispatch(
            elementaryClassesApi.util.updateQueryData(
              "getElementaryClass",
              elementaryClassId,
              () => {
                return elementaryClass;
              }
            )
          );
        } catch {}
      },
    }),
  }),
});
