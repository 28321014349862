import {createSelector} from "@reduxjs/toolkit";
import {IRootState} from "../../redux/reducers";
import {emptyAuth} from "../consts";
import {authApi} from "./authApi";

export const pingSelector = authApi.endpoints.ping.select();
export const getLastPrivacySelector = authApi.endpoints.getLastPrivacy.select();

export const selectPingData = (state: IRootState) =>
  pingSelector(state).data ?? emptyAuth;

export const selectHasCoursesNotification = createSelector(
  [selectPingData],
  (pingData) => {
    const coursesHistory = pingData.coursesHistory;

    return coursesHistory.some(
      (course) =>
        course.status === "Abilitante" && !course.userCourse?.dateQualification
    );
  }
);

export const selectIsLoggedIn = (state: IRootState) => state.auth.isLoggedIn;
export const selectIsPrivacyOpen = (state: IRootState) =>
  !!state.auth.isPrivacyOpen;
export const selectIsAppAlertOpen = (state: IRootState) =>
  !!state.auth.isAppAlertOpen;
