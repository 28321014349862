import {z} from "zod";
import {TargetsQuestionsKeys} from "../helpers/domandeObiettivi";
import {ICompaniesName} from "../User/types";
import {
  IIdentificationSnapshot,
  IPrivacyHistory,
  parseUserDB,
  User,
  UserDB,
} from "../Users/types";
import {IDeliveryFormData} from "./NewCapPage/ClosingSection/Delivery/DeliveryForm";
import {IHomeFormData} from "./NewCapPage/ClosingSection/HomeDrawer/HomeForm";
import {IEmploymentFormData} from "./NewCapPage/DenSection/EmploymentsDrawer/EmploymentForm";
import {IIncomesFormData} from "./NewCapPage/DenSection/IncomesDrawer/IncomesForm";
import {ISavingFormData} from "./NewCapPage/DenSection/SavingDrawer/SavingForm";
import {ITargetFormData} from "./NewCapPage/DenSection/TargetsDrawer/TargetsForm";
import {IHasIBIPsFormData} from "./NewCapPage/IBIPsSection/HasIBIPsDrawer/HasIBIPsForm";
import {IIddProfile} from "./NewCapPage/IBIPsSection/IBIPsDrawer/computeIdd";
import {IDomandeIdd} from "./NewCapPage/IBIPsSection/IBIPsDrawer/domandeIdd";
import {IIddFormData} from "./NewCapPage/IBIPsSection/IBIPsDrawer/IddForm";
import {IOverdraftsSectionFormData} from "./NewCapPage/OverdraftSection/OverdraftsDrawer/OverdraftsSingleForm";

export interface CapDB {
  contractor?: UserDB;
  createdAt: string;
  dateDelivery?: string;
  dateDen?: string;
  dateIdd?: string;
  dateOnboarding?: string;
  dateResponseValidation?: string;
  dateScoperture?: string;
  dateSendValidation?: string;
  dateStart: string;
  esignId?: string;
  id: string;
  isLast: boolean;
  jsonData?: string;
  jsonDelivery?: string;
  jsonDocs: string;
  jsonIdd?: string;
  jsonOnboarding?: string;
  jsonUserDelivery?: string;
  jsonUserDen?: string;
  jsonUserIdd?: string;
  jsonUserOnboarding?: string;
  jsonUserScoperture?: string;
  jsonUserSendValidation?: string;
  jsonUserStart: string;
  password: boolean;
  profitCenter?: string;
  responseValidation?: "0" | "1";
  status: "0" | "1";
  updatedAt: string;
  user: UserDB;
  version: string;
}

export interface Cap {
  contractor?: User;
  createdAt: string;
  dateDelivery?: string;
  dateDen?: string;
  dateIdd?: string;
  dateOnboarding?: string;
  dateResponseValidation?: string;
  dateScoperture?: string;
  dateSendValidation?: string;
  dateStart: string;
  esignId?: string;
  id: string;
  isLast: boolean;
  data?: IDataSnapshot;
  delivery?: IDeliverySnapshot;
  jsonDocs: string;
  idd?: IIddSnapshot;
  onboarding?: IOnboardingSnapshot;
  jsonUserDelivery?: string;
  jsonUserDen?: string;
  userIdd?: IUserSnapshot;
  jsonUserOnboarding?: string;
  jsonUserScoperture?: string;
  jsonUserSendValidation?: string;
  jsonUserStart: string;
  password: boolean;
  profitCenter?: string;
  responseValidation?: "0" | "1";
  status: "0" | "1";
  updatedAt: string;
  user: User;
  version: string;
}

export const parseCapDB = (capGeneric: CapDB): Cap => {
  const {
    contractor,
    jsonData,
    jsonDelivery,
    jsonIdd,
    jsonOnboarding,
    jsonUserIdd,
    password,
    user,
    ...rest
  } = capGeneric;

  const cap: Cap = {
    ...rest,
    contractor: contractor && parseUserDB(contractor),
    data: jsonData ? (JSON.parse(jsonData) as IDataSnapshot) : undefined,
    delivery: jsonDelivery
      ? (JSON.parse(jsonDelivery) as IDeliverySnapshot)
      : undefined,
    idd: jsonIdd ? (JSON.parse(jsonIdd) as IIddSnapshot) : undefined,
    onboarding: jsonOnboarding
      ? (JSON.parse(jsonOnboarding) as IOnboardingSnapshot)
      : undefined,
    password: !!password,
    userIdd: jsonUserIdd
      ? (JSON.parse(jsonUserIdd) as IUserSnapshot)
      : undefined,
    user: parseUserDB(user),
  };

  return cap;
};

export interface IUserSnapshot {
  cf: string;
  rui: string;
  name: string;
  surname: string;
}

export interface IDenSnapshot {
  home?: IHomeFormData;
  employments?: IEmploymentFormData;
  incomes?: IIncomesFormData;
  targets?: ITargetFormData;
  targetsWeights?: {[key in TargetsQuestionsKeys]: number[]};
  saving?: ISavingFormData;
  retirement?: {evaluated: boolean};
  injury?: {evaluated: boolean};
  tutela?: {evaluated: boolean};
  morte?: {evaluated: boolean};
}

export type IOverdraftsKey =
  | "beniCasaAnimali"
  | "tenoreVita"
  | "infortuniMalattiaPrevenzione"
  | "risparmioFuturo";
type IOverdraftsSnapshotSections = {
  [Key in IOverdraftsKey]?: IOverdraftsSectionFormData;
};
export interface IOverdraftsSnapshot extends IOverdraftsSnapshotSections {
  oneComplete?: boolean;
}

export interface IDataSnapshot extends IDenSnapshot {
  overdrafts?: IOverdraftsSnapshot;
  hasIBIPs?: IHasIBIPsFormData;
}

export interface IDeliverySnapshot {
  values?: IDeliveryFormData;
}

export interface IAssociatedOption extends IInvestmentOption {
  acceptanceLevel: number;
  matchingLevel: number;
  canSellProduct: Correspondence;
  compatibleWithEsgPreferences: Correspondence;
}

export interface IIddSnapshot {
  values: IIddFormData;
  idd: IIddProfile;
  questions: IDomandeIdd;
  dateSend?: string;
}

export interface IOnboardingSnapshot {
  identification: IIdentificationSnapshot;
  privacy: IPrivacyHistory;
}

export interface ICopyData {
  den?: {date: string; data: IDenSnapshot};
  overdrafts?: {date: string; data: {overdrafts: IOverdraftsSnapshot}};
  hasIBIPs?: {date: string; data: {hasIBIPs: IHasIBIPsFormData}};
}

export const capsSearchParamsSchema = z.object({
  contractor: z.string().optional(),
  dateStart: z.string().optional(),
  den: z.enum(["true", "false"]).optional(),
  globalContractor: z.enum(["true", "false"]).optional(),
  id: z.string().optional(),
  idd: z
    .enum([
      "all",
      "available",
      "not-available",
      "not-complete",
      "available||not-available",
    ])
    .optional(),
  limit: z
    .string()
    .default("50")
    .transform((str) => parseInt(str, 10)),
  marketing: z.enum(["true", "false"]).optional(),
  offset: z
    .string()
    .default("0")
    .transform((str) => parseInt(str, 10)),
  overdrafts: z.literal("available").optional(),
  sort: z.string().default("-dateStart"),
  user: z.string().optional(),
  validation: z.enum(["valid", "not-esign", "not-sent"]).optional(),
});
export type CapsParams = z.infer<typeof capsSearchParamsSchema>;

export interface ICompanyParams {
  hasElementaryClass: 1 | 0;
}

export interface IInvestmentCompany {
  a1Id: ICompaniesName;
  id: string;
  name: string;
  needCourse: "1" | "0";
  productElementaryClass?: Array<{name: string; id: string}>;
}
export interface IInvestmentProduct {
  id: string;
  name: string;
  company: IInvestmentCompany;
  instructionBefore: string;
  instructionAfter: string;
  url: string;
  dateStart?: string;
  dateEnd?: string;
}
export type Correspondence = "Y" | "N" | "Neutral";
export interface IInvestmentOption {
  id: string;
  product: IInvestmentProduct;
  name: string;
  description: string;
  risk: string;
  jsonDocs: string;
  investorTypeRetail: Correspondence;
  profileClientPreservation: Correspondence;
  profileClientCapitalGrowth: Correspondence;
  profileClientIncome: Correspondence;
  compatibleClientsNotBearCapitalLoss: Correspondence;
  compatibleClientsBearCapitalLoss: Correspondence;
  limitedCapitalLossLevel: string;
  compatibleClientsDoNotNeedCapitalGuarantee: Correspondence;
  compatibleClientsCanBearLossBeyondCapital: Correspondence;
  basicInvestor: Correspondence;
  informedInvestor: Correspondence;
  advancedInvestor: Correspondence;
  minimumRecommendedHoldingPeriod: string;
  dateStart?: string;
  dateEnd?: string;
}

export enum NewCapModalTypes {
  CreateContractor,
  UploadId,
  Privacy,
  PrivacySubscription,
  PrivacyForContractor,
  ESign,
  CompleteContractor,
  Employment,
  Home,
  Idd,
  Incomes,
  Saving,
  Target,
  ViewIdd,
  UpdateContractor,
  Delivery,
  OverDrafts,
}

export interface City {
  idObject: string;
  cNomeComune: string;
  cSiglaProvincia: string;
  bExist: string;
}
