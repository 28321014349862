import last from "lodash/last";
import {VFC} from "react";
import {matchPath, useLocation} from "react-router-dom";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {eSignsActions} from "../ESign/slice";
import {PDFTypes} from "../ESign/types";
import {IconPerson, IconSpinner} from "../Icons";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {IPrivacyHistory} from "../Users/types";
import {emptyAuth} from "./consts";
import PrivacyForm, {IPrivacyFormData} from "./PrivacyForm";
import "./Profile.scss";
import {authApi, authSelectors} from "./slice";

const PrivacyModal: VFC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isProfilePathActive = !!matchPath(location.pathname, "/user/profile");
  const {data: {permissions, user} = emptyAuth} = authApi.usePingQuery();
  const {data: {lastPrivacy} = {}} = authApi.useGetLastPrivacyQuery();
  const [privacy, {isError: isPrivacyError, error: privacyError}] =
    authApi.usePrivacyMutation();
  const isPrivacyOpen = useAppSelector(authSelectors.selectIsPrivacyOpen);

  if (!user || !lastPrivacy) {
    return null;
  }

  const openRequestOTPModal = (userId: string) => {
    dispatch(
      eSignsActions.modalOpened({
        pdfType: PDFTypes.Privacy,
        referenceId: userId,
      })
    );
  };

  const privacySubmit = async (values: IPrivacyFormData) => {
    await privacy({
      values,
      lastPrivacy,
    });
  };

  // Se sono contractor esigo che ci sia la firma
  const openAsContractor =
    permissions.includes("cap.contractor-read") && !user?.lastPrivacyEsignId;
  // Se non sono contractor va bene solo privacy
  const openAsNotContractor =
    !permissions.includes("cap.contractor-read") && !user?.jsonPrivacy;

  const userPrivacy = user?.jsonPrivacy;
  const privacyHistory = userPrivacy
    ? (JSON.parse(userPrivacy) as IPrivacyHistory)
    : undefined;
  const lastPrivacyHistory =
    privacyHistory && Array.isArray(privacyHistory)
      ? last(privacyHistory)
      : privacyHistory;
  const privacyInitialValues =
    lastPrivacyHistory === "true" ? undefined : lastPrivacyHistory?.values;

  const isUpdating =
    lastPrivacyHistory === "true" ||
    !!(
      lastPrivacyHistory &&
      lastPrivacy &&
      parseInt(lastPrivacy.version, 10) >
        parseInt(lastPrivacyHistory.version, 10)
    );

  return (
    <Modal
      isOpen={
        !isProfilePathActive &&
        (openAsContractor || openAsNotContractor || isPrivacyOpen)
      }
      backdrop="static"
      keyboard={false}
      className="modal-form"
    >
      <ModalHeader>
        <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
        Accettazione del consenso
      </ModalHeader>
      <ModalBody>
        {lastPrivacy ? (
          <PrivacyForm
            privacySubmit={privacySubmit}
            openRequestOTPModal={openRequestOTPModal}
            isUpdating={isUpdating}
            form="privacyFormESign"
            initialValues={privacyInitialValues}
            lastPrivacy={lastPrivacy}
            isPrivacyFail={isPrivacyError}
            privacyError={privacyError?.message}
          />
        ) : (
          <div className="text-center">
            <IconSpinner className="icon-spin me-2 my-4" />
            Caricamento privacy in corso...
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PrivacyModal;
