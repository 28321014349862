import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {authActions} from "../../User/slice";
import {proposalsApi} from "./proposalsApi";

type IState = DeepReadonly<{
  isESignsManagementModalOpen?: boolean;
  fileName?: string;
  forContractor?: boolean;
  chosenCap?: string;
  uploadingFileName?: string;
  deletingFileName?: string;
}>;

const recommendationsInitialState: IState = {};

const proposalsSlice = createSlice({
  name: "proposals",
  initialState: recommendationsInitialState,
  reducers: {
    capChosen: (state, action: PayloadAction<{capId: string | undefined}>) => {
      state.chosenCap = action.payload.capId;
    },
    eSignModalManagementOpened: (
      state,
      action: PayloadAction<{fileName: string; forContractor: boolean}>
    ) => {
      state.isESignsManagementModalOpen = true;
      state.fileName = action.payload.fileName;
      state.forContractor = action.payload.forContractor;
    },
    eSignModalManagementClosed: (state) => {
      state.isESignsManagementModalOpen = false;
      state.fileName = undefined;
      state.forContractor = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.loggedOut, () => {
        return recommendationsInitialState;
      })
      .addMatcher(
        proposalsApi.endpoints.uploadProposalDocument.matchPending,
        (state, action) => {
          state.uploadingFileName = action.meta.arg.originalArgs.fileName;
        }
      )
      .addMatcher(
        proposalsApi.endpoints.deleteProposalDocument.matchPending,
        (state, action) => {
          state.deletingFileName = action.meta.arg.originalArgs.fileName;
        }
      );
  },
});

export const proposalsActions = proposalsSlice.actions;
export const proposalsReducer = proposalsSlice.reducer;
