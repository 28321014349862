import {matchSorter} from "match-sorter";
import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {City} from "../types";

const apiUrl = "cities";

interface GetCityResponse {
  cities: City[];
}

export const citiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<
      City[],
      {exist?: boolean; name?: string; limit?: number} | undefined
    >({
      query: (options) => {
        const fixedOptions = {
          ...options,
          name: options?.name?.replace(/[‘’]/g, "'"),
        };

        return {
          url: apiUrl,
          params: fixedOptions,
        };
      },
      providesTags: [Tags.City],
      transformResponse: (
        {cities}: SuccessServerResponse<GetCityResponse>,
        meta,
        options
      ) => {
        const fixedOptions = {
          ...options,
          name: options?.name?.replace(/[‘’]/g, "'"),
        };
        const orderedCities = matchSorter(cities, fixedOptions?.name ?? "", {
          keys: ["cNomeComune"],
        });
        const estero = fixedOptions.exist
          ? []
          : [
              {
                idObject: "0",
                cNomeComune: "ESTERO",
                cSiglaProvincia: "EE",
                bExist: "0",
              },
            ];

        return [...orderedCities, ...estero];
      },
    }),
  }),
});
