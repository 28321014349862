import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {ICompanyParams, IInvestmentCompany} from "../types";

const apiUrl = "caps/companies";

interface GetCompanyResponse {
  companies: IInvestmentCompany[];
}

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<
      IInvestmentCompany[],
      Partial<ICompanyParams> | void
    >({
      query: (params) => {
        return {
          url: apiUrl,
          ...(params && {params}),
        };
      },
      providesTags: [Tags.Company],
      transformResponse: ({
        companies,
      }: SuccessServerResponse<GetCompanyResponse>) => {
        return companies;
      },
    }),
  }),
});
