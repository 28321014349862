import {createSlice} from "@reduxjs/toolkit";
import {authApi} from "./authApi";

interface AuthState {
  isLoggedIn: boolean | undefined;
  isPrivacyOpen?: boolean;
  isAppAlertOpen?: boolean;
}

const initialState: AuthState = {
  isLoggedIn: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.isLoggedIn = true;
    },
    loggedOut: (state) => {
      state.isLoggedIn = false;
    },
    privacyOpened: (state) => {
      state.isPrivacyOpen = true;
    },
    appAlertToggled: (state) => {
      state.isAppAlertOpen = !state.isAppAlertOpen;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.loggedOut, () => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.privacy.matchFulfilled, (state, action) => {
        state.isPrivacyOpen = false;
      });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
