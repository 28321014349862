import {batch} from "react-redux";
import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {beneMarketingsApi} from "../../BeneMarketings/slice";
import {
  BeneMarketing,
  BeneMarketingDB,
  parseBeneMarketingDB,
} from "../../BeneMarketings/types";
import {beneProposalsApi} from "../../BeneProposals/slice";
import {
  BeneProposal,
  BeneProposalDB,
  parseBeneProposalDB,
} from "../../BeneProposals/types";
import {capsApi} from "../../Caps/slice";
import {Cap, CapDB, parseCapDB} from "../../Caps/types";
import {elementaryClassesApi} from "../../ElementaryClasses/slice";
import {
  ElementaryClass,
  ElementaryClassDB,
  parseElementaryClassDB,
} from "../../ElementaryClasses/types";
import {proposalsApi} from "../../Proposals/slice";
import {parseProposalDB, Proposal, ProposalDB} from "../../Proposals/types";
import {recommendationsApi} from "../../Recommendation/slice";
import {
  parseRecommendationDB,
  Recommendation,
  RecommendationDB,
} from "../../Recommendation/types";
import {IRootState} from "../../redux/reducers";
import {updateAuthData} from "../../User/slice";
import {usersApi} from "../../Users/slice";
import {parseUserDB, User, UserDB} from "../../Users/types";
import {
  ESign,
  ESignDB,
  parseESignDB,
  PDFTypes,
  SignFEADocRequestData,
} from "../types";

const apiUrl = `esigns`;

interface CreateFEATransactionResponse {
  esign: ESignDB;
}

interface SignFEADocResponse {
  esign: ESignDB;
  bene?: BeneProposalDB;
  beneMarketing?: BeneMarketingDB;
  cap?: CapDB;
  elementaryClass?: ElementaryClassDB;
  proposal?: ProposalDB;
  user?: UserDB;
  recommendation?: RecommendationDB;
}
interface SignFEADocData {
  esign: ESign;
  beneProposal?: BeneProposal;
  beneMarketing?: BeneMarketing;
  cap?: Cap;
  elementaryClass?: ElementaryClass;
  proposal?: Proposal;
  user?: User;
  recommendation?: Recommendation;
}

export const eSignsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createFEATransaction: builder.mutation<
      ESign,
      | {
          contractorId: string;
          pdfType: PDFTypes;
          referenceId: string;
        }
      | undefined
    >({
      query: (data) => {
        return {
          url: `${apiUrl}/create-featransaction`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({
        esign,
      }: SuccessServerResponse<CreateFEATransactionResponse>) => {
        return parseESignDB(esign);
      },
    }),
    signFEADoc: builder.mutation<SignFEADocData, SignFEADocRequestData>({
      query: (data) => {
        return {
          url: `${apiUrl}/sign-feadoc`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({
        beneMarketing,
        bene,
        cap,
        elementaryClass,
        esign,
        proposal,
        recommendation,
        user,
      }: SuccessServerResponse<SignFEADocResponse>) => {
        return {
          beneMarketing: beneMarketing && parseBeneMarketingDB(beneMarketing),
          beneProposal: bene && parseBeneProposalDB(bene),
          cap: cap && parseCapDB(cap),
          elementaryClass:
            elementaryClass && parseElementaryClassDB(elementaryClass),
          esign: parseESignDB(esign),
          proposal: proposal && parseProposalDB(proposal),
          recommendation:
            recommendation && parseRecommendationDB(recommendation),
          user: user && parseUserDB(user),
        };
      },
      async onQueryStarted({pdfType}, {dispatch, queryFulfilled, getState}) {
        try {
          const {
            data: {
              beneMarketing,
              beneProposal,
              cap,
              elementaryClass,
              proposal,
              recommendation,
              user,
            },
          } = await queryFulfilled;

          batch(() => {
            if (
              [PDFTypes.Privacy, PDFTypes.PrivacySubscription].includes(pdfType)
            ) {
              updateAuthData(
                getState() as IRootState,
                Promise.resolve({data: {user}}),
                dispatch
              );
            }
            if (beneMarketing) {
              dispatch(
                beneMarketingsApi.util.updateQueryData(
                  "getBeneMarketing",
                  beneMarketing.id,
                  () => {
                    return beneMarketing;
                  }
                )
              );
              dispatch(
                beneMarketingsApi.util.invalidateTags([
                  {type: Tags.BeneMarketing, id: "LIST"},
                ])
              );
            }
            if (beneProposal) {
              dispatch(
                beneProposalsApi.util.updateQueryData(
                  "getBeneProposal",
                  beneProposal.id,
                  () => {
                    return beneProposal;
                  }
                )
              );
              dispatch(
                beneProposalsApi.util.invalidateTags([
                  {type: Tags.BeneProposal, id: "LIST"},
                ])
              );
            }
            if (cap) {
              dispatch(
                capsApi.util.updateQueryData("getCap", cap.id, () => {
                  return cap;
                })
              );
              dispatch(
                proposalsApi.util.invalidateTags([{type: Tags.Cap, id: "LIST"}])
              );
            }
            if (elementaryClass) {
              dispatch(
                elementaryClassesApi.util.updateQueryData(
                  "getElementaryClass",
                  elementaryClass.id,
                  () => {
                    return elementaryClass;
                  }
                )
              );
              dispatch(
                elementaryClassesApi.util.invalidateTags([
                  {type: Tags.ElementaryClass, id: "LIST"},
                ])
              );
            }
            if (proposal) {
              dispatch(
                proposalsApi.util.updateQueryData(
                  "getProposal",
                  proposal.id,
                  () => {
                    return proposal;
                  }
                )
              );
              dispatch(
                proposalsApi.util.invalidateTags([
                  {type: Tags.Proposal, id: "LIST"},
                ])
              );
            }
            if (recommendation) {
              dispatch(
                recommendationsApi.util.updateQueryData(
                  "getRecommendation",
                  recommendation.id,
                  () => {
                    return recommendation;
                  }
                )
              );
              dispatch(
                recommendationsApi.util.invalidateTags([
                  {type: Tags.Recommendation, id: "LIST"},
                ])
              );
            }
            if (user) {
              dispatch(
                usersApi.util.updateQueryData("getUser", user.id, () => {
                  return user;
                })
              );
              dispatch(
                usersApi.util.invalidateTags([{type: Tags.User, id: "LIST"}])
              );
            }
          });
        } catch {}
      },
    }),
  }),
});
