import {IRootState} from "../../redux/reducers";

export const selectChosenCapId = (state: IRootState) =>
  state.proposals.chosenCap;
export const selectIsESignsManagementModalOpen = (state: IRootState) =>
  !!state.proposals.isESignsManagementModalOpen;
export const selectOpenFileName = (state: IRootState) =>
  state.proposals.fileName;
export const selectOpenForContractor = (state: IRootState) =>
  state.proposals.forContractor;
export const selectUploadingFileName = (state: IRootState) =>
  state.proposals.uploadingFileName;
export const selectDeletingFileName = (state: IRootState) =>
  state.proposals.deletingFileName;
