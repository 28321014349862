import React from "react";
import {Link} from "react-router-dom";
import {Container} from "reactstrap";

import "./Footer.scss";

const parseFooter = () =>
  process.env
    .REACT_APP_FOOTER!.split("|")
    .map((part) => part.trim())
    .map((part) => {
      if (part.startsWith("mailto:")) {
        return (
          <a href={`mailto:${part.substring(7)}`} className="footer-link">
            {part.substring(7)}
          </a>
        );
      } else if (part.startsWith("link:")) {
        const parts = part.substring(5).split("](");
        const text = parts[0].replace("[", "");
        const href = parts[1].replace(")", "");
        return (
          <Link to={href} className="footer-link">
            {text}
          </Link>
        );
      } else {
        return part;
      }
    });

const Footer = () => (
  <Container id="app-footer" className="footer">
    <footer className="d-flex">
      <ul className="list-inline mb-0">
        {parseFooter()
          .map((part) => (
            <li className="list-inline-item" key={part.toString()}>
              {part}
            </li>
          ))
          .reduce((prev, curr) => (
            <>
              {prev}
              <li className="list-inline-item">|</li>
              {curr}
            </>
          ))}
      </ul>
      <small className="ms-auto text-nowrap align-self-end">
        Versione {process.env.REACT_APP_VERSION}
      </small>
    </footer>
  </Container>
);

export default Footer;
