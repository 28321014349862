import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import {ErrorCodes} from "../../helpers/errors";
import IApiError from "../../types/IApiError";
import {
  IChooseOptionsFormData,
  IChooseOptionsFormErrors,
} from "../NewRecommendationPage/ChooseOptionsForm";
import {
  parseRecommendationDB,
  Recommendation,
  RecommendationDB,
  RecommendationsParams,
  RecommendationStatus,
} from "../types";

const apiUrl = "recommendations";

interface CreateRecommendationResponse {
  recommendation: RecommendationDB;
}

interface GetRecommendationsResponse {
  numRecommendations: number;
  recommendations: RecommendationDB[];
}

interface GetRecommendationResponse {
  recommendation: RecommendationDB;
}

interface UpdateRecommendationResponse {
  recommendation: RecommendationDB;
}

interface AsyncWarningResponse {
  warn: IApiError;
}

export const recommendationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createRecommendation: builder.mutation<
      Recommendation,
      {
        cap: string;
        code: string;
        comment: string;
        jsonOptions: string;
        jsonRevisions?: string;
        status?: RecommendationStatus;
        user: string;
      }
    >({
      query: (data) => {
        return {
          url: apiUrl,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({
        recommendation,
      }: SuccessServerResponse<CreateRecommendationResponse>) => {
        return parseRecommendationDB(recommendation);
      },
      invalidatesTags: [{type: Tags.Recommendation, id: "LIST"}],
    }),
    getRecommendations: builder.query<
      {numRecommendations: number; recommendations: Recommendation[]},
      RecommendationsParams
    >({
      query: (params) => ({
        url: apiUrl,
        params,
      }),
      providesTags: (data) =>
        providesTagList(data?.recommendations, Tags.Recommendation),
      transformResponse: ({
        recommendations,
        numRecommendations,
      }: SuccessServerResponse<GetRecommendationsResponse>) => {
        return {
          recommendations: recommendations.map(parseRecommendationDB),
          numRecommendations,
        };
      },
    }),
    getRecommendation: builder.query<Recommendation, string>({
      query: (recommendationId) => ({
        url: `${apiUrl}/${recommendationId}`,
      }),
      transformResponse: ({
        recommendation,
      }: SuccessServerResponse<GetRecommendationResponse>) => {
        return parseRecommendationDB(recommendation);
      },
      providesTags: (result, error, recommendationId) => [
        {type: Tags.Recommendation, id: recommendationId},
      ],
    }),
    updateRecommendation: builder.mutation<
      Recommendation,
      {
        recommendationId: string;
        recommendation: Partial<RecommendationDB>;
      }
    >({
      query: ({recommendationId, recommendation}) => {
        return {
          url: `recommendations/${recommendationId}`,
          method: "PUT",
          body: recommendation,
        };
      },
      transformResponse: ({
        recommendation,
      }: SuccessServerResponse<UpdateRecommendationResponse>) => {
        return parseRecommendationDB(recommendation);
      },
      async onQueryStarted(
        {recommendationId, recommendation},
        {dispatch, queryFulfilled}
      ) {
        try {
          const {data: updatedRecommendation} = await queryFulfilled;
          dispatch(
            recommendationsApi.util.updateQueryData(
              "getRecommendation",
              recommendationId,
              () => {
                return updatedRecommendation;
              }
            )
          );
        } catch {}
      },
    }),
    recommendationAsyncWarning: builder.query<
      Partial<IChooseOptionsFormErrors> | undefined,
      Partial<IChooseOptionsFormData>
    >({
      query: (data) => {
        return {
          url: `${apiUrl}/validate`,
          method: "POST",
          body: data,
        };
      },
      transformResponse({warn}: SuccessServerResponse<AsyncWarningResponse>) {
        if (warn?.code && warn.code === ErrorCodes.CODE_ALREADY_EXISTS) {
          return {code: warn.message};
        } else {
          return undefined;
        }
      },
    }),
    recommendationSendNotification: builder.mutation<void, string>({
      query: (recommendationId) => {
        return {
          url: `${apiUrl}/${recommendationId}/recommendation-notification`,
          method: "POST",
        };
      },
    }),
  }),
});
