import {combineReducers} from "@reduxjs/toolkit";
import {reducer as formReducer} from "redux-form";
import {analyticsReducer} from "../Analytics/slice";
import {api, reducer as appReducer} from "../App/slice";
import {reducer as beneMarketingReducer} from "../BeneMarketings/slice";
import {reducer as beneProposalsReducer} from "../BeneProposals/slice";
import {capsReducer} from "../Caps/slice";
import {reducer as elementaryClassesReducer} from "../ElementaryClasses/slice";
import {proposalsReducer} from "../Proposals/slice";
import {reducer as recommendationsReducer} from "../Recommendation/slice";
import {authReducer} from "../User/slice";
import {eSignsReducer} from "../ESign/slice";

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appReducer,
  auth: authReducer,
  analytics: analyticsReducer,
  beneMarketings: beneMarketingReducer,
  beneProposals: beneProposalsReducer,
  caps: capsReducer,
  elementaryClasses: elementaryClassesReducer,
  eSign: eSignsReducer,
  form: formReducer,
  proposals: proposalsReducer,
  recommendations: recommendationsReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
