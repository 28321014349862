import {Cap, CapDB, IInvestmentProduct, parseCapDB} from "../Caps/types";
import {UserDB, parseUserDB, User} from "../Users/types";

export interface ProposalDB {
  cap: CapDB;
  code: string;
  contractor: UserDB;
  createdAt: string;
  date: string;
  dateEnd?: string;
  id: string;
  jsonDatesSendDarta?: string;
  jsonEsign: string;
  jsonProduct: string;
  jsonRevisions?: string;
  jsonUser: string;
  jsonUserAccept?: string;
  profitCenter?: string;
  status: ProposalStatus;
  updatedAt: string;
  user: UserDB;
  version: string;
}

export interface Proposal {
  cap: Cap;
  code: string;
  contractor: User;
  createdAt: string;
  date: string;
  dateEnd?: string;
  id: string;
  datesSendDarta?: ISendDartaHistory;
  eSign: ISnapshotEsign;
  product: IInvestmentProduct;
  revisions: Revision[];
  jsonUser: string;
  jsonUserAccept?: string;
  profitCenter?: string;
  status: ProposalStatus;
  updatedAt: string;
  user: User;
  version: string;
}

export const parseProposalDB = (proposalGeneric: ProposalDB): Proposal => {
  const {
    cap,
    contractor,
    jsonDatesSendDarta,
    jsonEsign,
    jsonProduct,
    jsonRevisions,
    user,
    ...rest
  } = proposalGeneric;
  const proposal: Proposal = {
    ...rest,
    cap: parseCapDB(cap),
    contractor: parseUserDB(contractor),
    datesSendDarta: jsonDatesSendDarta
      ? (JSON.parse(jsonDatesSendDarta) as ISendDartaHistory)
      : undefined,
    eSign: JSON.parse(jsonEsign) as ISnapshotEsign,
    product: JSON.parse(jsonProduct) as IInvestmentProduct,
    revisions: jsonRevisions ? JSON.parse(jsonRevisions) : [],
    user: parseUserDB(user),
  };

  return proposal;
};

export enum ProposalStatus {
  Deleted = "-1", // Proposal deleted.
  WaitingFiles = "0", // Proposal wait of upload required file.
  Complete = "1", // Proposal completed.
  WaitingAdvisorsESigns = "2", // Proposal request advisor esign.
  WaitingContractorsESign = "3", // Proposal request contractor esign.
  ToBeValidated = "4", // Proposal has to be validated by a back officer
  WaitReview = "5", // Proposal is waiting for a review
  Rejected = "6", // Proposal is archived
  SignedByAdvisor = "7", // Proposal is signed by Advisor
}

export interface Revision {
  date: string;
  text: string;
}

export type ISendDartaHistory = Array<{
  date: string;
}>;

export interface ProposalProductDocuments {
  id: string;
  jsonEsigns: string;
  nameInstructions: string;
  product: IInvestmentProduct;
  status: "1" | "0";
  url: string;
}

export interface IProposalESign {
  whoEsign: "advisor" | "contractor";
  required?: boolean;
  description: string;
  page: number;
  leftX: number;
  leftY: number;
  rightX: number;
  rightY: number;
  esignDate?: string;
  esignId?: string;
  esignUser?: {
    name: string;
    surname: string;
    cell: string;
    email: string;
    fiscalCode: string;
  };
}
export interface IProposalDocument {
  fileName: string;
  esigns: IProposalESign[];
  requiredFile?: boolean;
  uploaded?: boolean;
}
export interface ISnapshotEsign {
  totalEsigns: number;
  files: IProposalDocument[];
}
