import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import {IAddUserFormData} from "../../Users/AddUserPage/AddUserForm";
import {CreateUserResponse} from "../../Users/slice/usersApi";
import {ICreateContractorFormData} from "../NewCapPage/ClosingSection/CreateAccountDrawer/CreateContractorForm";
import {IUploadIdFormData} from "../NewCapPage/ClosingSection/IdentificationDrawer/UploadIdForm";
import {computeIdd} from "../NewCapPage/IBIPsSection/IBIPsDrawer/computeIdd";
import domandeIdd from "../NewCapPage/IBIPsSection/IBIPsDrawer/domandeIdd";
import {IIddFormData} from "../NewCapPage/IBIPsSection/IBIPsDrawer/IddForm";
import {Cap, CapDB, CapsParams, ICopyData, parseCapDB} from "../types";

const apiUrl = "caps";

interface CreateCapResponse {
  cap: CapDB;
}

interface GetCapsResponse {
  numCaps: number;
  caps: CapDB[];
}

interface GetCapResponse {
  cap: CapDB;
}

interface UpdateCapResponse {
  cap: CapDB;
}

interface CloseOverdraftsResponse {
  cap: CapDB;
}

interface SetIddResponse {
  cap: CapDB;
}

interface RequestValidationResponse {
  cap: CapDB;
}

export const capsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCap: builder.mutation<Cap, {contractor?: string; user: string}>({
      query: (data) => {
        return {
          url: apiUrl,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({cap}: SuccessServerResponse<CreateCapResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
    }),
    getCaps: builder.query<
      {
        numCaps: number;
        caps: Cap[];
      },
      CapsParams
    >({
      query: (params) => ({
        url: apiUrl,
        params,
      }),
      providesTags: (data) => providesTagList(data?.caps, Tags.Cap),
      transformResponse: ({
        caps,
        numCaps,
      }: SuccessServerResponse<GetCapsResponse>) => {
        return {
          caps: caps.map(parseCapDB),
          numCaps,
        };
      },
    }),
    getMarketingCaps: builder.query<
      {
        numCaps: number;
        caps: Cap[];
      },
      CapsParams
    >({
      query: (params) => ({
        url: `${apiUrl}/check-bene-marketing`,
        params,
      }),
      providesTags: () => [{type: Tags.Cap, id: "MARKETING_LIST"}],
      transformResponse: ({
        caps,
        numCaps,
      }: SuccessServerResponse<GetCapsResponse>) => {
        return {
          caps: caps.map(parseCapDB),
          numCaps,
        };
      },
    }),
    getCap: builder.query<Cap, string>({
      query: (capId) => ({
        url: `${apiUrl}/${capId}`,
      }),
      transformResponse: ({cap}: SuccessServerResponse<GetCapResponse>) => {
        return parseCapDB(cap);
      },
      providesTags: (result, error, capId) => [{type: Tags.Cap, id: capId}],
    }),
    updateCap: builder.mutation<
      Cap,
      {
        capId: string;
        cap: Partial<
          Omit<CapDB, "user" | "contractor"> & {
            user: string;
            contractor: string;
          }
        >;
      }
    >({
      query: ({capId, cap}) => {
        return {
          url: `${apiUrl}/${capId}`,
          method: "PUT",
          body: cap,
        };
      },
      transformResponse: ({cap}: SuccessServerResponse<UpdateCapResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId, cap}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    createOrUpdateCap: builder.mutation<
      Cap,
      | {
          capId: undefined;
          loggedUserId: string;
          cap: Partial<
            Omit<CapDB, "user" | "contractor"> & {
              user: string;
              contractor: string;
            }
          >;
        }
      | {
          capId: string;
          loggedUserId: string;
          cap: Partial<
            Omit<CapDB, "user" | "contractor"> & {
              user: string;
              contractor: string;
            }
          >;
        }
    >({
      query: ({capId, loggedUserId, cap}) => {
        if (capId) {
          return {
            url: `${apiUrl}/${capId}`,
            method: "PUT",
            body: cap,
          };
        } else {
          return {
            url: apiUrl,
            method: "POST",
            body: {...cap, user: loggedUserId},
          };
        }
      },
      transformResponse: ({
        cap,
      }: SuccessServerResponse<CreateCapResponse | UpdateCapResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId, cap}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", updatedCap.id, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    closeOverdrafts: builder.mutation<Cap, string>({
      query: (capId) => {
        return {
          url: `${apiUrl}/${capId}/close-overdraft`,
          method: "POst",
        };
      },
      transformResponse: ({
        cap,
      }: SuccessServerResponse<CloseOverdraftsResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted(capId, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    createContractor: builder.mutation<
      Cap,
      {capId: string; values: ICreateContractorFormData}
    >({
      queryFn: async (
        {capId, values},
        baseQueryApi,
        extraOptions,
        baseQuery
      ) => {
        // Creo utente nuovo
        const newUser: IAddUserFormData = {
          ...values,
          // USID di default è la mail
          usid: values.email,
          rolesId: [],
        };
        newUser.rolesId[7] = true;

        const createUserResponse = await baseQuery({
          url: "users",
          method: "POST",
          body: newUser,
        });

        if (createUserResponse.error) {
          return {error: createUserResponse.error};
        }
        const createUserSuccessResponse =
          createUserResponse.data as SuccessServerResponse<CreateUserResponse>;

        // Aggiungo utente al cap
        const updatedCapResponse = await baseQuery({
          url: `${apiUrl}/${capId}`,
          method: "PUT",
          body: {contractor: createUserSuccessResponse.user.id},
        });

        if (updatedCapResponse.error) {
          return {error: updatedCapResponse.error};
        }
        const updatedCapSuccessResponse =
          updatedCapResponse.data as SuccessServerResponse<UpdateCapResponse>;

        return {data: parseCapDB(updatedCapSuccessResponse.cap)};
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId, values}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    uploadIdFromCap: builder.mutation<
      Cap,
      {userId: string; capId: string; values: IUploadIdFormData}
    >({
      queryFn: async (
        {userId, capId, values},
        baseQueryApi,
        extraOptions,
        baseQuery
      ) => {
        // Carico documento
        const updateUserResponse = await baseQuery({
          url: `users/${userId}/upload-id`,
          method: "POST",
          body: values,
        });

        if (updateUserResponse.error) {
          return {error: updateUserResponse.error};
        }

        // Documento caricato, ora resetto l'onboarding del cap
        const updatedCapResponse = await baseQuery({
          url: `${apiUrl}/${capId}`,
          method: "PUT",
          body: {jsonOnboarding: ""},
        });

        if (updatedCapResponse.error) {
          return {error: updatedCapResponse.error};
        }
        const updatedCapSuccessResponse =
          updatedCapResponse.data as SuccessServerResponse<UpdateCapResponse>;

        return {data: parseCapDB(updatedCapSuccessResponse.cap)};
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    setIdd: builder.mutation<Cap, {values: IIddFormData; capId: string}>({
      query: ({capId, values}) => {
        const idd = computeIdd(values);

        return {
          url: `${apiUrl}/${capId}/set-idd`,
          method: "POST",
          body: {
            idd: JSON.stringify(idd),
            questions: JSON.stringify(domandeIdd),
            values: JSON.stringify(values),
          },
        };
      },
      transformResponse: ({cap}: SuccessServerResponse<SetIddResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    requestValidation: builder.mutation<Cap, {capId: string; email?: string}>({
      query: ({capId, email}) => {
        return {
          url: `${apiUrl}/${capId}/send-validation`,
          method: "POST",
          body: {email},
        };
      },
      transformResponse: ({
        cap,
      }: SuccessServerResponse<RequestValidationResponse>) => {
        return parseCapDB(cap);
      },
      invalidatesTags: [{type: Tags.Cap, id: "LIST"}],
      async onQueryStarted({capId}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedCap} = await queryFulfilled;
          dispatch(
            capsApi.util.updateQueryData("getCap", capId, () => {
              return updatedCap;
            })
          );
        } catch {}
      },
    }),
    identificationNotification: builder.mutation<void, string>({
      query: (capId) => `${apiUrl}/${capId}/identification-notification`,
    }),
    getCopy: builder.query<ICopyData, string>({
      query: (capId) => `${apiUrl}/${capId}/copy-data`,
      providesTags: [Tags.Copy],
    }),
  }),
});
