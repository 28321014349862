import moment from "moment/moment";
import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import {IUploadIdFormData} from "../../Caps/NewCapPage/ClosingSection/IdentificationDrawer/UploadIdForm";
import {IAddUserFormData} from "../AddUserPage/AddUserForm";
import {Course, parseUserDB, User, UserDB} from "../types";
import {IDetailFormData} from "../UserDetailPage/DetailForm";

interface GetUsersResponse {
  users: UserDB[];
}

export interface CreateUserResponse {
  user: UserDB;
}

export interface UpdateUserResponse {
  user: UserDB;
}

interface GetUserResponse {
  user: UserDB;
}

interface UploadBulkFileResponse {
  filePath: string;
  usersData: Array<{[Key: string]: string}>;
}
interface UploadBulkFileData {
  filePath: string;
  uploadReport: Array<{[Key: string]: string}>;
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => "users",
      providesTags: (data) => providesTagList(data, Tags.User),
      transformResponse({users}: SuccessServerResponse<GetUsersResponse>) {
        return users.map(parseUserDB);
      },
    }),
    createUser: builder.mutation<User, IAddUserFormData>({
      query: (newUser) => {
        const birthday =
          newUser.birthday &&
          moment(newUser.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
        const dateRui =
          newUser.dateRui &&
          moment(newUser.dateRui, "DD/MM/YYYY").format("YYYY-MM-DD");
        const dateRuiCompany =
          newUser.dateRuiCompany &&
          moment(newUser.dateRuiCompany, "DD/MM/YYYY").format("YYYY-MM-DD");

        return {
          url: "users",
          method: "POST",
          body: {...newUser, birthday, dateRui, dateRuiCompany},
        };
      },
      transformResponse: ({
        user,
      }: SuccessServerResponse<CreateUserResponse>) => {
        return parseUserDB(user);
      },
      invalidatesTags: [{type: Tags.User, id: "LIST"}],
    }),
    updateUser: builder.mutation<
      User,
      {userId: string; updateUser: Partial<IDetailFormData>}
    >({
      query: ({userId, updateUser}) => {
        const birthday =
          updateUser.birthday &&
          moment(updateUser.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
        const dateRui =
          updateUser.dateRui &&
          moment(updateUser.dateRui, "DD/MM/YYYY").format("YYYY-MM-DD");
        const dateRuiCompany =
          updateUser.dateRuiCompany &&
          moment(updateUser.dateRuiCompany, "DD/MM/YYYY").format("YYYY-MM-DD");

        return {
          url: `users/${userId}`,
          method: "PUT",
          body: {
            ...updateUser,
            birthday,
            dateRui,
            dateRuiCompany,
          },
        };
      },
      transformResponse: ({
        user,
      }: SuccessServerResponse<UpdateUserResponse>) => {
        return parseUserDB(user);
      },
      async onQueryStarted({userId, updateUser}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedUser} = await queryFulfilled;
          dispatch(
            usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
              for (let u = 0; u < draft.length; u++) {
                if (draft[u].id === userId) {
                  draft[u] = updatedUser;
                  break;
                }
              }
            })
          );
          dispatch(
            usersApi.util.updateQueryData("getUser", userId, () => {
              return updatedUser;
            })
          );
        } catch (e) {
          console.log("error", e);
        }
      },
    }),
    resetContractorPassword: builder.mutation<void, string>({
      query: (contractorId) => ({
        url: `users/${contractorId}/re-send-credential-contractor`,
        method: "POST",
      }),
    }),
    getUser: builder.query<User, string>({
      query: (userId) => `users/${userId}`,
      transformResponse: ({user}: SuccessServerResponse<GetUserResponse>) => {
        return parseUserDB(user);
      },
      providesTags: (result, error, userId) => [{type: Tags.User, id: userId}],
      async onQueryStarted(userId, {dispatch, queryFulfilled}) {
        try {
          const {data: user} = await queryFulfilled;
          dispatch(
            usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
              for (let u = 0; u < draft.length; u++) {
                if (draft[u].id === userId) {
                  draft[u] = user;
                  break;
                }
              }
            })
          );
        } catch (e) {
          console.log("error", e);
        }
      },
    }),
    uploadId: builder.mutation<
      User,
      {userId: string; values: IUploadIdFormData}
    >({
      query: ({userId, values}) => ({
        url: `users/${userId}/upload-id`,
        method: "POST",
        body: values,
      }),
      transformResponse: ({user}: SuccessServerResponse<{user: User}>) => {
        return user;
      },
    }),
    getCoursesHistory: builder.query<Course[], string>({
      query: (userId) => `users/${userId}/courses-history`,
      transformResponse: (
        data: SuccessServerResponse<{coursesHistory: Course[]}>
      ) => {
        return data.coursesHistory;
      },
      providesTags: [Tags.CoursesHistory],
    }),
    uploadBulkFile: builder.mutation<
      UploadBulkFileData,
      {file: File; action: "create" | "update" | "delete" | "courses"}
    >({
      query: ({file, action}) => ({
        url: `users/${action}-bulk-report`,
        method: "POST",
        body: {file},
      }),
      transformResponse: ({
        filePath,
        usersData,
      }: SuccessServerResponse<UploadBulkFileResponse>) => {
        return {filePath, uploadReport: usersData};
      },
    }),
    acceptBulkFile: builder.mutation<
      Array<{[Key: string]: string}>,
      {filePath: string; action: "create" | "update" | "delete" | "courses"}
    >({
      query: ({filePath, action}) => ({
        url: `users/${action}-bulk`,
        method: "POST",
        body: {filePath},
      }),
      transformResponse: ({
        usersData,
      }: SuccessServerResponse<{
        usersData: Array<{[Key: string]: string}>;
      }>) => {
        return usersData;
      },
    }),
  }),
});
