import {flatten} from "flat";
import {Dispatch} from "@reduxjs/toolkit";

export const focusFirstInvalid = (
  errors: {[key: string]: string} | undefined,
  dispatch: Dispatch<any>,
  submitError: any,
  props: any
) => {
  if (errors && Object.keys(errors).length > 0) {
    // "appiattisco" l'oggetto errors
    const flatErrors: {[key: string]: string} = flatten(errors);

    // sistemo il doppio selector per gli array: a.0.b -> a[0].b
    const regex = /\.([0-9]+)/gm;
    const subst = `[$1]`;
    Object.keys(flatErrors).forEach((key) => {
      if (regex.test(key)) {
        flatErrors[key.replace(regex, subst)] = flatErrors[key];
      }
    });

    const form = document.querySelector(`form[name="${props.form}"]`);
    const selector = Object.keys(flatErrors)
      .map((fieldName) => `[name="${fieldName}"]`)
      .join(",");
    const errorEl: HTMLInputElement | null = (
      form ? form : document
    ).querySelector(selector);

    if (errorEl) {
      errorEl.focus();
    }
  } else {
    throw submitError;
  }
};
