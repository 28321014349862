import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {authActions} from "../../User/slice";

type IState = DeepReadonly<{
  chosenCap?: string;
  isChosenCapFromCap?: boolean;
}>;

const recommendationsInitialState: IState = {};

const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState: recommendationsInitialState,
  reducers: {
    capChosen: (
      state,
      action: PayloadAction<{capId: string | undefined; isFromCap?: boolean}>
    ) => {
      state.chosenCap = action.payload.capId;
      state.isChosenCapFromCap = action.payload.isFromCap;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.loggedOut, () => {
      return recommendationsInitialState;
    });
  },
});

export const actions = recommendationsSlice.actions;
export const reducer = recommendationsSlice.reducer;
