import {api, SuccessServerResponse, Tags} from "../../App/slice";
import {IAssociatedOption, IInvestmentOption} from "../types";

const apiUrl = "caps/options";

interface GetOptionResponse {
  options: IInvestmentOption[];
}

interface GetAssociatedOptionResponse {
  options: IAssociatedOption[];
}

export const optionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query<IInvestmentOption[], void>({
      query: () => apiUrl,
      providesTags: [{type: Tags.Option, id: "LIST"}],
      transformResponse: ({
        options,
      }: SuccessServerResponse<GetOptionResponse>) => {
        return options;
      },
    }),
    getAssociatedOptions: builder.query<IAssociatedOption[], string>({
      query: (capId) => {
        return {
          url: apiUrl,
          method: "GET",
          params: {"only-associated-options": true, capId},
        };
      },
      providesTags: [{type: Tags.Option, id: "ASSOCIATED_LIST"}],
      transformResponse: ({
        options,
      }: SuccessServerResponse<GetAssociatedOptionResponse>) => {
        return options;
      },
    }),
  }),
});
