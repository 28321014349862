import {IRootState} from "../../redux/reducers";
import {NewCapModalTypes} from "../types";

export const selectIsModalOpen = (
  state: IRootState,
  modalType: NewCapModalTypes,
  id?: string
) => {
  if (!id) {
    return state.caps.isModalOpen[modalType];
  }
  return state.caps.isModalOpen[modalType] && state.caps.isModalOpenId === id;
};

export const selectChosenCap = (state: IRootState) => state.caps.chosenCap;

export const selectErrorExistingUserId = (state: IRootState) =>
  state.caps.errorExistingUserId;

export const selectErrorCreateContractorAnotherAdvisor = (state: IRootState) =>
  state.caps.errorCreateContractorAnotherAdvisor;
