import {
  api,
  providesTagList,
  SuccessServerResponse,
  Tags,
} from "../../App/slice";
import {
  parseProposalDB,
  Proposal,
  ProposalDB,
  ProposalProductDocuments,
} from "../types";

const apiUrl = "proposals";
const proposalProductDocumentsApiUrl = "proposal-product-documents";

interface CreateProposalResponse {
  proposal: ProposalDB;
}

interface GetProposalsResponse {
  numProposals: number;
  proposals: ProposalDB[];
}

interface GetProposalResponse {
  proposal: ProposalDB;
}

interface UpdateProposalResponse {
  proposal: ProposalDB;
}

interface UploadProposalDocumentResponse {
  proposal: ProposalDB;
}

interface DeleteProposalDocumentResponse {
  proposal: ProposalDB;
}

interface DuplicateProposalResponse {
  proposal: ProposalDB;
  proposal_archived: ProposalDB;
}

interface GetProposalProductDocumentsListResponse {
  proposalProductDocuments: ProposalProductDocuments[];
}

interface SendToDartaResponse {
  proposal: ProposalDB;
}

export const proposalsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createProposal: builder.mutation<
      Proposal,
      {
        cap: string;
        contractor: string;
        user: string;
        jsonProduct: string;
        code: string;
      }
    >({
      query: (data) => {
        return {
          url: apiUrl,
          method: "POST",
          body: data,
        };
      },
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<CreateProposalResponse>) => {
        return parseProposalDB(proposal);
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
    }),
    getProposals: builder.query<
      {numProposals: number; proposals: Proposal[]},
      void
    >({
      query: () => ({
        url: apiUrl,
      }),
      providesTags: (data) => providesTagList(data?.proposals, Tags.Proposal),
      transformResponse: ({
        proposals,
        numProposals,
      }: SuccessServerResponse<GetProposalsResponse>) => {
        return {proposals: proposals.map(parseProposalDB), numProposals};
      },
    }),
    getProposal: builder.query<Proposal, string>({
      query: (proposalId) => ({
        url: `${apiUrl}/${proposalId}`,
      }),
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<GetProposalResponse>) => {
        return parseProposalDB(proposal);
      },
      providesTags: (result, error, proposalId) => [
        {type: Tags.Proposal, id: proposalId},
      ],
    }),
    updateProposal: builder.mutation<
      Proposal,
      {
        proposalId: string;
        proposal: Partial<ProposalDB>;
      }
    >({
      query: ({proposalId, proposal}) => {
        return {
          url: `proposals/${proposalId}`,
          method: "PUT",
          body: proposal,
        };
      },
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<UpdateProposalResponse>) => {
        return parseProposalDB(proposal);
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
      async onQueryStarted({proposalId, proposal}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedProposal} = await queryFulfilled;
          dispatch(
            proposalsApi.util.updateQueryData("getProposal", proposalId, () => {
              return updatedProposal;
            })
          );
        } catch {}
      },
    }),
    uploadProposalDocument: builder.mutation<
      Proposal,
      {
        proposalId: string;
        filePdf: File;
        fileName: string;
      }
    >({
      query: ({proposalId, filePdf}) => {
        return {
          url: `${apiUrl}/${proposalId}/upload-proposal-pdf`,
          method: "POST",
          body: {filePdf},
        };
      },
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<UploadProposalDocumentResponse>) => {
        return parseProposalDB(proposal);
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
      async onQueryStarted({proposalId}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedProposal} = await queryFulfilled;
          dispatch(
            proposalsApi.util.updateQueryData("getProposal", proposalId, () => {
              return updatedProposal;
            })
          );
        } catch {}
      },
    }),
    deleteProposalDocument: builder.mutation<
      Proposal,
      {proposalId: string; fileName: string}
    >({
      query: ({proposalId, fileName}) => {
        return {
          url: `${apiUrl}/${proposalId}/delete-file?fileName=${fileName}`,
          method: "DELETE",
        };
      },
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<DeleteProposalDocumentResponse>) => {
        return parseProposalDB(proposal);
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
      async onQueryStarted({proposalId}, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedProposal} = await queryFulfilled;
          dispatch(
            proposalsApi.util.updateQueryData("getProposal", proposalId, () => {
              return updatedProposal;
            })
          );
        } catch {}
      },
    }),
    duplicateProposal: builder.mutation<
      {newProposal: Proposal; oldProposal: Proposal},
      string
    >({
      query: (proposalId) => {
        return {
          url: `${apiUrl}/${proposalId}/duplicate`,
          method: "POST",
        };
      },
      transformResponse: ({
        proposal,
        proposal_archived,
      }: SuccessServerResponse<DuplicateProposalResponse>) => {
        return {
          newProposal: parseProposalDB(proposal),
          oldProposal: parseProposalDB(proposal_archived),
        };
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
      async onQueryStarted(proposalId, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedProposal} = await queryFulfilled;
          dispatch(
            proposalsApi.util.updateQueryData("getProposal", proposalId, () => {
              return updatedProposal.newProposal;
            })
          );
          dispatch(
            proposalsApi.util.updateQueryData(
              "getProposal",
              updatedProposal.oldProposal.id,
              () => {
                return updatedProposal.oldProposal;
              }
            )
          );
        } catch {}
      },
    }),
    getProposalProductDocuments: builder.query<
      ProposalProductDocuments[],
      void
    >({
      query: () => proposalProductDocumentsApiUrl,
      transformResponse: ({
        proposalProductDocuments,
      }: SuccessServerResponse<GetProposalProductDocumentsListResponse>) => {
        return proposalProductDocuments;
      },
      providesTags: (result, error, proposalId) => [
        Tags.ProposalProductDocuments,
      ],
    }),
    sendProposalToDarta: builder.mutation<Proposal, string>({
      query: (proposalId) => {
        return {
          url: `${apiUrl}/${proposalId}/send-to-darta`,
          method: "POST",
        };
      },
      transformResponse: ({
        proposal,
      }: SuccessServerResponse<SendToDartaResponse>) => {
        return parseProposalDB(proposal);
      },
      invalidatesTags: [{type: Tags.Proposal, id: "LIST"}],
      async onQueryStarted(proposalId, {dispatch, queryFulfilled}) {
        try {
          const {data: updatedProposal} = await queryFulfilled;
          dispatch(
            proposalsApi.util.updateQueryData("getProposal", proposalId, () => {
              return updatedProposal;
            })
          );
        } catch {}
      },
    }),
    sendProposalNotification: builder.mutation<void, string>({
      query: (proposalId) => {
        return {
          url: `${apiUrl}/${proposalId}/proposal-notification`,
          method: "POST",
        };
      },
    }),
  }),
});
