export function isApiError(error: any): error is IApiError {
  return error.code !== undefined;
}

interface IBaseApiError {
  code: number;
  message: string;
  stack?: string;
}

type IApiError<E extends {} = {}> = IBaseApiError & E;

export default IApiError;
