import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authActions} from "../../User/slice";
import {User} from "../../Users/types";
import {PDFTypes} from "../types";

interface ESignsState {
  additionalParams?: {[key: string]: string};
  inPlaceOfContractor?: User; // Valorizzato solo se si sta firmando al posto del cliente
  isModalOpen: boolean;
  isUpdatingPhone?: boolean;
  pdfType?: PDFTypes;
  referenceId?: string;
}

const eSignsInitialState: ESignsState = {
  isModalOpen: false,
};

const eSignsSlice = createSlice({
  name: "eSigns",
  initialState: eSignsInitialState,
  reducers: {
    modalOpened: (
      state,
      action: PayloadAction<{
        additionalParams?: {[key: string]: string};
        inPlaceOfContractor?: User; // Valorizzato solo se si sta firmando al posto del cliente
        pdfType: PDFTypes;
        referenceId: string;
      }>
    ) => {
      state.isModalOpen = true;
      state.pdfType = action.payload.pdfType;
      state.referenceId = action.payload.referenceId;
      state.inPlaceOfContractor = action.payload.inPlaceOfContractor;
      state.additionalParams = action.payload.additionalParams;
    },
    modalClosed: (state) => {
      state.isModalOpen = false;
      state.pdfType = undefined;
      state.referenceId = undefined;
      state.inPlaceOfContractor = undefined;
      state.additionalParams = undefined;
      state.isUpdatingPhone = false;
    },
    editNumberFormOpened: (state) => {
      state.isUpdatingPhone = true;
    },
    editNumberFormClosed: (state) => {
      state.isUpdatingPhone = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.loggedOut, () => {
      return eSignsInitialState;
    });
  },
});

export const eSignsReducer = eSignsSlice.reducer;
export const eSignsActions = eSignsSlice.actions;
